import { PencilIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../../../components/ui";

const Actions = props => {
  const { id, values } = props || {};
  const navigate = useNavigate();

  const handleEdit = () => {
    const { type } = props?.values || {};
    switch (type?.name) {
      case "Artist":
        return navigate(`/artist?status=selectListWorking&listId=${id}`);
      case "Artist_working":
        return navigate("/artist?status=selectListWorking");
      case "Artwork":
        return navigate(`/artworks?status=selectListWorking&listId=${id}`);
      case "Artwork_Image":
        return navigate(
          `/artworks?status=imageSelectListWorking&imageListId=${id}`,
        );
      case "Artwork_Image_working":
        return navigate("/artworks?status=imageSelectListWorking");
      case "Artwork_working":
        return navigate(`/artworks?status=selectListWorking`);
      case "Company":
        return navigate(`/companies?status=selectListWorking&listId=${id}`);
      case "Company_working":
        return navigate("/companies?status=selectListWorking");
      case "Contact":
        return navigate(`/contacts?status=selectListWorking&listId=${id}`);
      case "Contact_working":
        return navigate(`/contacts?status=selectListWorking`);
      case "Exhibition":
        return navigate(`/exhibition?status=selectListWorking&listId=${id}`);
      case "Exhibition_working":
        return navigate("/exhibition?status=selectListWorking");
      case "Instance":
        return navigate(`/instance?status=selectListWorking&listId=${id}`);
      case "Instance_Image":
        return navigate(
          `/instance?status=imageSelectListWorking&imageListId=${id}`,
        );
      case "Instance_Image_working":
        return navigate("/instance?status=imageSelectListWorking");
      case "Instance_working":
        return navigate(`/instance?status=selectListWorking`);
      case "Invoice":
        return navigate(`/invoice?status=selectListWorking&listId=${id}`);
      case "Invoice_working":
        return navigate(`/invoice?status=selectListWorking`);
      case "Image":
        return navigate(
          `/instance?status=imageSelectListWorking&imageListId=${id}`,
        );
      case "Image_working":
        return navigate(`/instance?status=imageSelectListWorking`);
      case "Job":
        return navigate(`/jobs?status-selectListWorking&listId=${id}`);
      case "Job_working":
        return navigate("/jobs?status=selectListWorking");
      case "Offer":
        return navigate(`/offer?status=selectListWorking&listId=${id}`);
      case "Offer_working":
        return navigate(`/offer?status=selectListWorking`);
      case "Reservation":
        return navigate(`/reservations?status=selectListWorking&listId=${id}`);
      case "Reservation_working":
        return navigate("/reservations?status=selectListWorking");
      case "WarehouseItem":
        return navigate(`/warehouse?status=selectListWorking&listId=${id}`);
      case "WarehouseItem_working":
        return navigate(`/warehouse?status=selectListWorking`);
    }
  };

  if (typeof id == "number" && values?.type?.name) {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <IconButton variant="clean" title="Edit Select List">
              <PencilIcon
                className={`h-4 w-4 cursor-pointer text-gray-400`}
                onClick={handleEdit}
              />
            </IconButton>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
