import { gql } from "@apollo/client";
import { Offer as offer } from "../fragments";

const FETCH_OFFER = props => gql`
  query FetchOffer($id: Int) {
    offer(id: $id) {
      ${offer(props)}
    }
  }
`;

const FETCH_OFFERS = props => gql`
  query FetchOffers($input: OfferFilterInput!) {
    offers(input: $input) {
      count
      edges {
        ${offer(props)}
      }
    }
  }
`;

const FETCH_OPTIMISED_OFFERS = props => gql`
  query FetchOffers($input: OfferFilterInput!) {
    offers(input: $input) {
      count
      edges {
        id
        artwork {
          title
          artists {
            name
          }
          imagesSummary {
            imgT
          }
        }
        date
        statusId
        currency {
          id
          code
        }
        amount
        priority
        hold {
          name
        }
        dateClosed
        user
        notes
      }
    }
  }
`;

const FETCH_OFFERS_V2 = props => gql`
  query FetchOffersV2($input: AdvOfferFilterInput!) {
    offersV2(input: $input) {
      count
      edges {
        ${offer(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_OFFERS_LIST = props => gql`
  query FetchOffersList($input: AdvOfferFilterInput!) {
    offersV2(input: $input) {
      count
      edges {
        id
        artwork {
          title
          artists {
            name
          }
          imagesSummary {
            imgT
          }
        }
        updatedAt
        date
        status {
          description
        }
        currency {
          id
          code
        }
        tbiAmount
        dateClosed
        notes
        user
      }
      sqlQuery
    }
  }
`;

export {
  FETCH_OFFER,
  FETCH_OFFERS,
  FETCH_OFFERS_V2,
  FETCH_OFFERS_LIST,
  FETCH_OPTIMISED_OFFERS,
};
