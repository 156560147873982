/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Image as image } from "../fragments";
import { Artwork as artwork } from "../fragments";

const DELETE_IMAGES = gql`
  mutation DeleteImages($input: DeleteImagesInput!) {
    deleteImages(input: $input) {
      success
      error
    }
  }
`;

const GET_IMAGE_URLS = props => gql`
  mutation GetImageUrls($input: GetImageUrlsInput!) {
    getImageUrls(input: $input) {
      error
      success
      artworks {
        ${artwork(props)}
      }
      urls
    }
  }
`;

const MOVE_IMAGES = gql`
  mutation MoveImages($input: MoveImagesInput!) {
    moveImages(input: $input) {
      success
      error
    }
  }
`;

const UPDATE_IMAGE = props => gql`
  mutation UpdateImage($input: UpdateImageInput!) {
    updateImageObj(input: $input) {
      error
      image {
        ${image(props)}
      }
      success
    }
  }
`;

const UPDATE_IMAGE_ORDER = props => gql`
  mutation UpdateImageOrder($input: UpdateImageOrderInput!) {
    updateImageOrder(input: $input) {
      success
      error
      images {
        ${image(props)}
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($input: UploadImageInput!) {
    uploadImage(input: $input) {
      error
      success
    }
  }
`;

export {
  DELETE_IMAGES,
  GET_IMAGE_URLS,
  MOVE_IMAGES,
  UPDATE_IMAGE,
  UPDATE_IMAGE_ORDER,
  UPLOAD_IMAGE,
};
