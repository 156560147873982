import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import { Table, SelectList } from "../../../../../../components/Functional";
import { Button, getInput, Modal } from "../../../../../../components/ui";

import useUser from "../../../../../../hooks/useUser";
import { FETCH_OPTIMISED_OFFERS as fetchOffers } from "../../../../../../graphql/query/Offer";
import { FETCH_ARTISTS as fetchArtists } from "../../../../../../graphql/query/Artist";
import { FETCH_CURRENCIES } from "../../../../../../graphql/query/Currency";
import { FETCH_OFFER_STATUSES } from "../../../../../../graphql/query/OfferStatus";
import { UPDATE_OFFER as updateOfferMutation } from "../../../../../../graphql/mutation/Offer";
import { Checkbox } from "../../../../../../components/ui/Popovers";
import SectionHeader from "../../components/SectionHeader";

import AddOffer from "./components/AddOffer";
import MoveOfferModal from "./components/MoveOfferModal";
import RefuseOfferModal from "./components/RefuseOfferModal";

const ContactOffers = props => {
  const { contact } = props || {};
  const { hasPermission } = useUser();
  const { data: { artists = [] } = {} } = useQuery(fetchArtists());
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { offerStatuses = [] } = {} } = useQuery(FETCH_OFFER_STATUSES);
  const formik = useFormik({
    initialValues: {},
  });

  const toggleProps = props => {
    const { id } = props || {};
    const name = `moveOffers.ids._${id}`;

    return {
      designType: "checkbox",
      type: "toggle",
      name,
      ...formik.getFieldProps(name),
      onChange: value =>
        formik.getFieldHelpers(name).setValue(value ? true : undefined),
    };
  };

  const SelectAll = props => {
    const { rows } = props || {};

    const checkboxProps = {
      formik,
      label: "Offers",
      name: "moveOffers",
      rows,
    };

    return <Checkbox {...checkboxProps} />;
  };

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "offers",
    headers: [
      {
        label: SelectAll,
        type: "custom",
        component: props => getInput(toggleProps(props)),
      },
      {
        label: "Date",
        name: "date",
        type: "date",
      },
      { label: "Image", name: "artwork.imagesSummary.imgT", type: "image" },
      {
        label: "Artist",
        name: "artists",
        type: "custom",
        component: props => (
          <div
            className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
            style={{ maxWidth: 150 }}
          >
            {(
              props?.values?.artwork?.artists?.map(item => item?.name) || []
            )?.join(", ")}
          </div>
        ),
      },
      {
        label: "Artwork Title",
        name: "artwork.title",
        type: "label",
        widthInPx: 150,
      },
      {
        label: "Status",
        name: "statusId",
        sortKey: "statusId",
        type: "input",
        inputType: "multi-select",
        options: offerStatuses?.map(item => ({
          value: item?.id,
          label: item?.description,
        })),
      },
      {
        label: "Currency",
        name: "currency.code",
        sortKey: "currencyId",
        type: "label",
      },
      { label: "Amount", name: "amount", sortKey: "amount", type: "currency" },
      {
        label: "Top Deal",
        name: "priority",
        type: "input",
        inputType: "checkbox",
        singleCheckbox: true,
        options: [{ value: -1 }],
      },
      {
        label: "Hold",
        name: "hold.name",
        type: "custom",
        component: props => {
          return (
            <Link
              className="underline"
              to={`/instance/${props?.values?.hold?.id}/details`}
            >
              {props?.values?.hold?.name}
            </Link>
          );
        },
      },
      {
        label: "Closed",
        name: "dateClosed",
        sortKey: "dateClosed",
        type: "date",
      },
      { label: "User", name: "user", sortKey: "user", type: "label" },
      {
        label: "Notes",
        name: "notes",
        sortKey: "notes",
        type: "input",
        inputType: "text",
      },
    ],
    inputs: [
      { label: "Date From", name: "from", type: "date", view: "partial" },
      { label: "Date To", name: "to", type: "date", view: "partial" },
      {
        label: "Artist",
        name: "artistId",
        type: "multi-select",
        view: "partial",
        options: artists?.map(item => ({ label: item.name, value: item.id })),
      },
      {
        label: "Offer Status",
        name: "statusId",
        type: "multi-select",
        view: "partial",
        options: offerStatuses?.map(item => ({
          label: item.description,
          value: item.id,
        })),
      },
      {
        label: "Currency",
        name: "currencyId",
        type: "multi-select",
        view: "partial",
        options: currencies?.map(item => ({
          label: `${item.code} ${item.description ? `- ${item.description}` : ""}`,
          value: item.id,
        })),
      },
      { label: "Amount", name: "tbiAmount", type: "number", view: "partial" },
      {
        label: "Close Date From",
        name: "dateClosedFrom",
        type: "date",
        view: "partial",
      },
      {
        label: "Close Date To",
        name: "dateClosedTo",
        type: "date",
        view: "partial",
      },
      { label: "Notes", name: "notes", type: "text", view: "partial" },
      { label: "User", name: "user", type: "text", view: "partial" },
    ],
    FETCH_QUERY: fetchOffers(),
    variables: {
      contactId: contact?.id,
      order: ["date", "DESC"],
    },
    UPDATE_MUTATION: updateOfferMutation(),
    className: "sm:px-0 lg:px-0",
  };

  const disabled =
    Object.keys(formik?.values?.moveOffers?.ids || {})?.length == 0 ||
    !hasPermission("MOVE_OFFER");
  const modalProps = {
    body: MoveOfferModal,
    closeOnBackdrop: false,
    contact,
    disabled,
    hideCloseButton: true,
    scale: "md",
    values: formik?.values,
    onClose: () => {
      formik?.resetForm();
    },
  };

  const createListProps = {
    ids: Object.keys(formik?.values?.moveOffers?.ids || {})?.map(item =>
      parseInt(item.replace("_", "")),
    ),
    onSuccess: () => formik?.resetForm(),
    typeId: 12,
  };

  const offersActions = [
    {
      component: () => <AddOffer contact={contact} />,
    },
    {
      component: () => <SelectList.CreateList {...createListProps} />,
    },
    {
      component: () => {
        return (
          <Modal {...{ ...modalProps, body: RefuseOfferModal }}>
            <Button
              label="Refuse All Offers"
              action="black"
              disabled={disabled}
            />
          </Modal>
        );
      },
    },
    {
      component: () => {
        return (
          <Modal {...modalProps}>
            <Button label="Move Offer" action="black" disabled={disabled} />
          </Modal>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Offers" actions={offersActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ContactOffers;
