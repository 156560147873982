import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { GridInputs } from "../../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../../components/ui";

import { CREATE_ITEM as createItemMutation } from "../../../../../../../graphql/mutation/Item";

import { FETCH_PAY_TYPES } from "../../../../../../../graphql/query/PayType";
import { FETCH_NOMINALS } from "../../../../../../../graphql/query/Nominal";
import { FETCH_INVOICE_INSTANCE } from "../../../../../../../graphql/query/Invoice";
import AddCreditNoteBody from "./AddCreditNote";
import useUser from "../../../../../../../hooks/useUser";

const AddTransactionBody = props => {
  const { closeModal, invoice } = props || {};

  const [
    createItem,
    { loading, error: createItemError, reset: createItemReset },
  ] = useMutation(createItemMutation(), {
    refetchQueries: ["FetchItemsV2", "FetchInvoice"],
  });

  const { data: { payTypes = [] } = {} } = useQuery(FETCH_PAY_TYPES);
  const { data: { nominals = [], loading: fetchNominalLoading } = {} } =
    useQuery(FETCH_NOMINALS);
  const [prevValues, setPrevValues] = useState({});
  const [error, setError] = useState({});

  const isPayment = typeId =>
    payTypes.find(type => type.numberId === typeId).cd === -1;

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: Yup.object({
      commission: Yup.number().min(0).nullable().typeError("Invalid input"),
      commissionRate: Yup.number().min(0).typeError("Invalid input"),
      consigneeSplit: Yup.number().min(0).nullable().typeError("Invalid input"),
      consigneeSplitRate: Yup.number()
        .min(0)
        .nullable()
        .typeError("Invalid input"),
      nominalId: Yup.number().typeError("Can only be a numeric value"),
      taxAmount: Yup.number().min(0).nullable().typeError("Invalid input"),
      typeId: Yup.number()
        .required("Please select a type")
        .typeError("Please select a type"),
    }),
    onSubmit: async values => {
      createItem({
        variables: {
          input: {
            invoiceId: invoice?.id,
            ...(formik?.values?.typeId === 8
              ? { instanceId: values?.ref }
              : {}),
            typeId: values?.typeId,
            ref: formik?.values?.typeId === 8 ? instanceRef : values?.ref,
            nominalId: values?.nominalId,
            description: values?.description,
            discount: values?.discount || undefined,
            discountRate: values?.discountRate || undefined,
            showDiscount: values?.showDiscount,
            amount: isPayment(values?.typeId)
              ? -Math.abs(values?.amount)
              : values?.amount,
            taxAmount: values?.taxAmount || 0,
            commission: values?.commission || undefined,
            commissionRate: values?.commissionRate || 0,
            consigneeSplit: values?.consigneeSplit || undefined,
            consigneeSplitRate: values?.consigneeSplitRate || 0,
            oldCompanyId: invoice?.company?.id || undefined,
            newCompanyId: invoice?.contact?.contactCompany?.id || undefined,
          },
        },
      }).then(resp => {
        const { data: { createItem: { success } = {} } = {} } = resp || {};
        if (success) {
          closeModal?.();
          formik?.resetForm?.();
        }
      });
    },
  });

  const {
    data: {
      invoiceInstance: {
        error: invoiceInstanceError,
        ref: instanceRef,
        ...instance
      } = {},
    } = {},
    loading: invoiceInstanceLoading,
  } = useQuery(FETCH_INVOICE_INSTANCE, {
    skip: typeof formik?.values?.ref !== "number",
    variables: {
      input: { instanceId: formik?.values?.ref, invoiceId: invoice?.id },
    },
  });

  useEffect(() => {
    formik?.values?.ref && setError("");
  }, [formik?.values?.ref]);

  useEffect(() => {
    !instance?.success && setError(invoiceInstanceError);
    instance?.success && formik.setValues({ ...formik?.values, ...instance });
  }, [Object.keys(instance)?.length]);

  const getCalculations = ({ changedKeys, values }) => {
    const { amount, typeId } = values || {};
    const calculations = { ...values };

    const recalculate = () => {
      const prevValues = { ...calculations };

      if (!changedKeys.includes("commission")) {
        calculations.commission =
          (amount - calculations.discount) *
          (calculations.commissionRate / 100);
      }

      if (!changedKeys.includes("commissionRate")) {
        calculations.commissionRate =
          (calculations.commission / (amount - calculations.discount)) * 100;
      }

      if (!changedKeys.includes("consigneeSplit")) {
        calculations.consigneeSplit =
          (amount - calculations.discount) *
          (calculations.consigneeSplitRate / 100);
      }

      if (!changedKeys.includes("consigneeSplitRate")) {
        calculations.consigneeSplitRate =
          (calculations.consigneeSplit / (amount - calculations.discount)) *
          100;
      }

      if (!changedKeys.includes("discount")) {
        calculations.discount = amount * (calculations.discountRate / 100);
      }

      if (!changedKeys.includes("discountRate")) {
        calculations.discountRate = (calculations.discount / amount) * 100;
      }

      if (
        changedKeys.some(k =>
          ["amount", "discount", "discountRate", "typeId"].includes(k),
        )
      ) {
        if (typeof amount === "number") {
          const type = payTypes.filter(pt => pt.numberId === typeId);
          if (type.length > 0 && type[0].tax !== 0) {
            calculations.taxAmount =
              (amount - (calculations.discount || 0)) * (invoice.taxRate / 100);
          } else {
            calculations.taxAmount = 0;
          }
        }
      }

      // remove Nan from calculations
      Object.keys(calculations).forEach(key => {
        if (isNaN(calculations[key])) {
          delete calculations[key];
        }
        // to fixed 2
        if (typeof calculations[key] === "number") {
          calculations[key] = Number(calculations[key].toFixed(2));
        }
      });

      // Check if any values have changed in the current iteration
      const hasChanged = Object.keys(calculations).some(
        key => calculations[key] !== prevValues[key],
      );

      // If any values have changed, recalculate recursively
      if (hasChanged) {
        recalculate();
      }
    };

    recalculate();
    return calculations;
  };

  useEffect(() => {
    const { amount } = formik?.values || {};

    if (amount) {
      const changedKeys = Object.keys(formik?.values || {}).filter(
        key => formik?.values?.[key] !== prevValues?.[key],
      );
      if (changedKeys?.length === 0) return;
      const newValues = getCalculations({
        changedKeys,
        values: formik?.values,
      });

      // set new values
      if (Object.keys(newValues || {})?.length > 0) {
        setPrevValues({ ...formik?.values, ...newValues });
        formik.setValues({
          ...formik?.values,
          taxAmount: newValues.taxAmount,
          discount: newValues.discount,
          discountRate: newValues.discountRate,
        });
        return;
      }
    }

    setPrevValues(formik?.values);
  }, [
    formik?.values?.amount,
    formik?.values?.discount,
    formik?.values?.discountRate,
    formik?.values?.typeId,
  ]);

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  const validInstanceCompanyId = [
    +invoice?.companyId,
    +invoice?.company?.crossInvoiceId,
  ].filter(id => id);

  const inputs = {
    className: "grid grid-cols-2 gap-8 mt-5",
    inputs: [
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            options:
              invoice?.typeId === 2
                ? payTypes
                    .filter(payType => payType.numberId !== 6)
                    .map(item => ({
                      label: item.name,
                      value: item.numberId,
                    }))
                : payTypes?.map(item => ({
                    label: item.name,
                    value: item.numberId,
                  })),
            type: "multi-select",
          },
          {
            appendMissingOption: true,
            isLoading: fetchNominalLoading,
            label: "Nominal",
            name: "nominalId",
            options: nominals?.map(item => ({
              label: item?.label,
              value: item?.id,
            })),
            type: "multi-select",
          },
          {
            label: "Reference",
            name: "ref",
            type: formik?.values?.typeId === 8 ? "instanceSearch" : "text",
            filterInput: [
              { companyId: validInstanceCompanyId },
              { statusId: [1, 4, 5, 10] },
            ],
          },
          { label: "Description", name: "description", type: "textarea" },
        ],
      },
      {
        className: "grid-cols-2 gap-y-4 gap-x-8",
        inputs: [
          { label: "Discount", name: "discount", type: "number" },
          { label: "%", name: "discountRate", type: "number" },
          {
            className: "col-span-2",
            designType: "threeStateCheckbox",
            label: "Hide",
            name: "showDiscount",
            type: "toggle",
          },
          {
            className: "col-span-2",
            label: "Amount",
            name: "amount",
            type: "number-currency",
          },
          {
            className: "col-span-2",
            label: "Tax",
            name: "taxAmount",
            type: "number-currency",
            // disable tax field if type is not Sale or Credit Note
            disabled: [6, 8].includes(formik?.values?.typeId) ? false : true,
          },
          {
            label: "Advisor Commission",
            name: "commission",
            type: "number-currency",
          },
          { label: "%", name: "commissionRate", type: "number-currency" },
          {
            label: "Consignor Split",
            name: "consigneeSplit",
            type: "number-currency",
          },
          { label: "%", name: "consigneeSplitRate", type: "number-currency" },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const resetAll = () => {
    closeModal();
    createItemReset();
    formik?.resetForm?.();
  };

  if (createItemError) {
    return (
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 text-2xl font-bold">
            <div>Error: Adding Transaction</div>
          </div>
          <div className="flex gap-4">
            <Button
              action="default"
              label="Cancel"
              onClick={() => {
                resetAll();
              }}
            />
          </div>
        </div>
        <div>{createItemError?.message || ""}</div>
      </div>
    );
  }

  if (formik?.values?.typeId !== 6) {
    return (
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 text-2xl font-bold">
            <div>Add Transaction</div>
            {error && (
              <small className="text-sm font-medium text-red-600">
                {error}
              </small>
            )}
          </div>
          <div className="flex gap-4">
            <Button
              action="default"
              label="Cancel"
              onClick={() => {
                resetAll();
              }}
            />
            <Button
              label={invoiceInstanceLoading ? "Fetching Instance" : "Create"}
              onClick={formik.submitForm}
              loading={loading || invoiceInstanceLoading}
              disabled={loading || invoiceInstanceLoading || hasErrors}
            />
          </div>
        </div>
        <div className="grid">
          <GridInputs {...inputProps} />
        </div>
      </div>
    );
  } else {
    return <AddCreditNoteBody invoice={invoice} resetAll={resetAll} />;
  }
};

const AddTransactionModal = props => {
  const { hasPermission } = useUser();

  const modalProps = {
    body: AddTransactionBody,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    disabled: !hasPermission("UPDATE_ITEM"),
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <Button
        label="Add Transaction"
        disabled={!hasPermission("UPDATE_ITEM")}
      />
    </Modal>
  );
};

export { AddTransactionModal, AddTransactionBody };
