import { gql } from "@apollo/client";
import { Item as item } from "../fragments";

const FETCH_ITEMS_V2 = props => gql`
  query FetchItemsV2($input: AdvItemFilterInput!) {
    itemsV2(input: $input) {
      count
      edges {
        ${item(props)}
      }
      sqlQuery
      itemTotal
    }
  }
`;

const FETCH_OPTIMISED_ITEMS_V2 = props => gql`
  query FetchItemsV2($input: AdvItemFilterInput!) {
    itemsV2(input: $input) {
      count
      edges {
        id
        instance {
          edition {
            artwork {
              title
              artists {
                name
              }
              imagesSummary {
                imgT
              }
            }
          }
        }
        invoice {
          id
          reference
          number
          date
          currency {
            code
          }
        }
        price
        typeId
      }
      itemTotal
    }
  }
`;

const FETCH_ITEM = props => gql`
  query FetchItem($id: Int!) {
    item(id: $id) {
      ${item(props)}
    }
  }
`;

export { FETCH_ITEM, FETCH_ITEMS_V2, FETCH_OPTIMISED_ITEMS_V2 };
