import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";

import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { REMOVE_INVOICE_ITEM as removeInvoiceItemMutation } from "../../../../../../../graphql/mutation/Invoice";

const Actions = props => {
  const { item, invoiceId } = props || {};

  const isExported = item?.values?.exported === 1;
  const isCreditNote = item?.values?.typeId === 6;
  const id = item?.id;

  const [removeInvoiceItem, { loading, error, reset }] = useMutation(
    removeInvoiceItemMutation({
      instance: { edition: { artwork: { imagesSummary: { imgT: {} } } } },
      type: {},
    }),
    {
      refetchQueries: ["FetchItemsV2", "FetchInvoice"],
    },
  );
  const deleteModalProps = {
    title: error ? "Error deleting" : "Are you sure?",
    scale: "sm",
    disabled: isCreditNote || isExported,
    description: error
      ? error.message
      : "Would you like to delete this transaction?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = async () => {
        const response = await removeInvoiceItem({
          variables: {
            input: {
              id: invoiceId,
              itemId: id,
            },
          },
        });

        if (response?.data?.removeInvoiceItem?.success) {
          closeModal();
        }
      };

      if (error) {
        return (
          <div className="flex justify-end mt-4">
            <Button
              className="mr-3"
              label="Close"
              onClick={() => {
                reset();
                closeModal();
              }}
            />
          </div>
        );
      }

      return (
        <div className="flex justify-end mt-4">
          <Button
            className="mr-3"
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  const iconButton = (
    <IconButton
      variant="clean"
      title={isExported || isCreditNote ? "" : "Delete Transaction"}
    >
      <TrashIcon
        className={`h-4 w-4 cursor-pointer text-gray-400 ${isExported || isCreditNote ? "cursor-default opacity-40" : ""}`}
      />
    </IconButton>
  );

  if (typeof id == "number") {
    return (
      <div className="flex text-sm font-medium text-right user-list-actions">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...deleteModalProps}>{iconButton}</Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
