/* eslint-disable max-len */
import ArtworkDetail from "./components/ArtworkDetail";
import SectionHeader from "../../components/SectionHeader";
import { DetailViewCarousel } from "../../../../../../components/ui";
import {
  CopyClipboard,
  ExportImage,
  ManageImage,
  UpdateImageInfo,
} from "../../../../../../components/Modals/ImageActions";

const ArtworkDetails = props => {
  const { artwork, formik, artworkId } = props || {};

  const artworkActions = [
    {
      component: props => {
        return (
          <>
            <ManageImage
              {...props}
              images={artwork?.images}
              artwork={artwork}
            />
          </>
        );
      },
    },
  ];

  const imageActions = [
    {
      component: props => {
        return (
          <>
            <UpdateImageInfo {...props} />
            <CopyClipboard {...props} />
            <ExportImage {...props} artwork={artwork} />
          </>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader
        title={
          <div>
            Artwork {artwork?.id}
            <div className={`mt-3 text-sm font-medium text-black`}>
              {artwork?.images ? `${artwork.images.length} images` : ""}
            </div>
          </div>
        }
        actions={artworkActions}
      />
      <div className="w-full px-16 py-8">
        <div className="flex flex-col space-y-16">
          <DetailViewCarousel
            imageList={artwork?.images}
            artworkId={artworkId}
            actions={imageActions}
          />
          <ArtworkDetail formik={formik} submitOnBlur={true} />
        </div>
      </div>
    </div>
  );
};

export default ArtworkDetails;
