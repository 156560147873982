import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import GridIcon from "../../../../assets/icons/GridIcon";
import ContactIcon from "../../../../assets/icons/ContactIcon";
import PdfIcon from "../../../../assets/icons/PdfIcon";
import EmailIcon from "../../../../assets/icons/EmailIcon";
import FileTextIcon from "../../../../assets/icons/FileTextIcon";
import ImageIcon from "../../../../assets/icons/ImageIcon";
import LayersIcon from "../../../../assets/icons/LayersIcon";
import ListIcon from "../../../../assets/icons/ListIcon";
import LogoutIcon from "../../../../assets/icons/LogoutIcon";
import OfferIcon from "../../../../assets/icons/OfferIcon";
import ServerIcon from "../../../../assets/icons/ServerIcon";
import SettingsIcon from "../../../../assets/icons/SettingsIcon";
import ShipmentIcon from "../../../../assets/icons/ShipmentIcon";
import WarehouseIcon from "../../../../assets/icons/WarehouseIcon";
// import SalesCrmIcon from '../../../../assets/icons/SalesCrmIcon'
import useUser from "../../../../hooks/useUser";
import ArtistIcon from "../../../../assets/icons/ArtistIcon";
import ExhibitionIcon from "../../../../assets/icons/ExhibitionIcon";
import ReservationIcon from "../../../../assets/icons/ReservationIcon";

const SlideoutComponent = () => {
  const ref = useRef(null);
  const location = useLocation();
  const [gridStatus, changeGridStatus] = useState(false);
  const { hasPermission } = useUser();

  const handleGridStatus = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      changeGridStatus(false);
    }
  };

  useEffect(() => {
    changeGridStatus(false);
    document.addEventListener("click", handleGridStatus);
    return () => {
      document.removeEventListener("click", handleGridStatus);
    };
  }, [location.pathname]);

  const modules = [
    {
      icon: PdfIcon,
      label: "PDF Generator",
      name: "pdf",
      pathname: "/",
      permission: "PDF_GENERATOR_MODULE",
    },
    {
      icon: EmailIcon,
      label: "Email Module",
      name: "email",
      pathname: "/email",
      permission: "EMAIL_MODULE",
    },
    {
      icon: ContactIcon,
      label: "Contacts",
      name: "contact",
      pathname: "/contacts",
      permission: "CONTACT_MODULE",
    },
    {
      icon: SettingsIcon,
      label: "Admin",
      name: "admin",
      pathname: "/admin/users",
      permission: "ADMIN_MODULE",
    },
    {
      icon: ImageIcon,
      label: "Artworks",
      name: "artworks",
      pathname: "/artworks",
      permission: "ARTWORK_MODULE",
    },
    {
      icon: LayersIcon,
      label: "Instance",
      name: "instance",
      pathname: "/instance",
      permission: "INSTANCE_MODULE",
    },
    {
      icon: FileTextIcon,
      label: "Invoice",
      name: "invoice",
      pathname: "/invoice",
      permission: "INVOICE_MODULE",
    },
    {
      icon: LogoutIcon,
      label: "Consignment",
      name: "consignment",
      pathname: "/consignment",
      permission: "CONSIGNMENT_MODULE",
    },
    {
      icon: ListIcon,
      label: "Select Lists",
      name: "select-lists",
      pathname: "/select-lists",
      permission: "SELECT_LIST_MODULE",
    },
    {
      icon: ShipmentIcon,
      label: "Shipment",
      name: "shipment",
      pathname: "/shipments",
      permission: "SHIPMENT_MODULE",
    },
    {
      icon: WarehouseIcon,
      label: "Warehouse",
      name: "warehouse",
      pathname: "/warehouse",
      permission: "WAREHOUSE_MODULE",
    },
    {
      icon: ServerIcon,
      label: "Jobs",
      name: "jobs",
      pathname: "/jobs",
      permission: "JOB_MODULE",
    },
    {
      icon: OfferIcon,
      label: "Offer",
      name: "offer",
      pathname: "/offer",
      permission: "OFFER_MODULE",
    },
    {
      icon: FileTextIcon,
      label: "Reports",
      name: "reports",
      pathname: "/reports",
      permission: "REPORT_MODULE",
    },
    {
      icon: ArtistIcon,
      label: "Artists",
      name: "artist",
      pathname: "/artist",
      permission: "ARTIST_MODULE",
    },
    {
      icon: ExhibitionIcon,
      label: "Exhibitions",
      name: "exhibition",
      pathname: "/exhibition",
      permission: "EXHIBITION_MODULE",
    },
    {
      icon: ReservationIcon,
      label: "Reservations",
      name: "reservation",
      pathname: "/reservations",
      permission: "RESERVATION_MODULE",
    },
    // {icon: SalesCrmIcon, label: 'Sales CRM', name: 'sales-crm', pathname: '/sales-crm', permission: 'CONTACT_MODULE'},
  ];

  return (
    <div className="relative" ref={ref}>
      <div
        onClick={() => changeGridStatus(!gridStatus)}
        className="cursor-pointer"
      >
        <GridIcon />
      </div>
      {gridStatus && (
        // eslint-disable-next-line max-len
        <div className="absolute right-[calc(100%_-_20px)] top-[calc(100%_+_10px)] z-10 max-h-[350px] overflow-y-auto rounded border border-black bg-white px-6 py-4">
          <div className="grid grid-cols-2 gap-4 w-max">
            {modules?.map((item, index) =>
              hasPermission(item.permission) ? (
                <Link
                  key={index}
                  className="flex w-[90px] min-w-[90px] cursor-pointer flex-col items-center justify-center"
                  to={{ pathname: item?.pathname }}
                >
                  {item?.icon?.()}
                  <div className="mt-4 text-xs">{item?.label}</div>
                </Link>
              ) : null,
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideoutComponent;
