import Entity from "./Entity";
import reservationInstanceFrag from "./ReservationInstance";
import ReservationType from "./ReservationType";

const Reservation = ({
  createdBy = false,
  reservationInstance = false,
  reservationUser = false,
  type = false,
}) => `
  categoryId
  ${
    createdBy
      ? `createdBy {
      ${Entity}
    }`
      : ""
  }
  date
  dateModified
  dateDue
  description
  id
  reservationInstanceCount
  ${
    reservationInstance
      ? `reservationInstances {
      ${reservationInstanceFrag(reservationInstance)}
    }`
      : ""
  }
  reservationUserId
  ${
    reservationUser
      ? `reservationUser {
      ${Entity}
    }`
      : ""
  }
  ${
    type
      ? `type {
      ${ReservationType}
    }`
      : ""
  }
  typeId
  userId
  updatedAt
`;

export default Reservation;
