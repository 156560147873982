import { useQuery } from "@apollo/client";
import useUser from "../../../../../../hooks/useUser";
import SectionHeader from "../../components/SectionHeader";
import { Link } from "react-router-dom";
import { GridInputs, Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";
import { getInput } from "../../../../../../components/ui";
import { FETCH_RESERVATION_INSTANCES_V2 as fetchReservationInstancesV2 } from "../../../../../../graphql/query/ReservationInstance";
import { FETCH_RESERVATION_TYPES } from "../../../../../../graphql/query/ReservationType";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import { RELEASE_RESERVATION_INSTANCE } from "../../../../../../graphql/mutation/ReservationInstance";
import AddReservationInstance from "./components/AddReservationInstance";

const ReservationEdit = ({
  disabled,
  formik,
  submitOnBlur = false,
  reservation,
}) => {
  const { data: { reservationTypes = [] } = {} } = useQuery(
    FETCH_RESERVATION_TYPES,
  );
  const { data: { entities = {} } = {} } = useQuery(FETCH_ENTITIES);

  const inputs = {
    className: "grid gap-4",
    inputs: [
      {
        className: "grid md:grid-cols-4 gap-4",
        inputs: [
          {
            label: "ID",
            name: "id",
            type: "text",
            disabled: true,
            submitOnBlur,
          },

          {
            label: "Created By",
            name: "createdBy.name",
            type: "text",
            disabled: true,
          },
        ],
      },
      {
        className: "grid grid-cols-4 gap-4",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            options: reservationTypes.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
            disabled: disabled,
            submitOnBlur,
          },
          {
            label: "Date",
            name: "date",
            type: "date",
            disabled: disabled,
            submitOnBlur,
          },
          {
            label: "Reserved For",
            name: "reservationUserId",
            type: "multi-select",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
            disabled: disabled,
            submitOnBlur,
          },
          {
            label: "End Of Reservation",
            name: "dateDue",
            type: "date",
            disabled: disabled || reservation?.categoryId === 2,
            submitOnBlur,
          },
        ],
      },
      {
        className: "",
        inputs: [
          {
            label: "Description",
            name: "description",
            type: "textarea",
            disabled: disabled,
            submitOnBlur,
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return <GridInputs {...inputProps} />;
};

const ReservationInstances = props => {
  const { reservation } = props;

  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_RESERVATION_INSTANCE");

  const RenderArtists = props => {
    const { artists } = props;

    if (artists?.length > 0) {
      const artistNames = artists.map(artist => artist.name);
      return <>{artistNames.join(", ")}</>;
    } else {
      return <>Artist Not found</>;
    }
  };

  const tableProps = {
    headers: [
      {
        label: "ID",
        type: "custom",
        name: "id",
        component: ({ values }) => {
          return (
            <Link
              to={`/instance/${values?.instance?.id}/details`}
              target="_blank"
              className="underline"
              disabled={disabled}
            >
              {values?.instance?.id || values?.instance?.id}
            </Link>
          );
        },
      },
      {
        label: "Artist",
        type: "custom",
        component: props => {
          return (
            <RenderArtists
              artists={props?.values?.instance?.edition?.artwork?.artists}
              {...props}
            />
          );
        },
        name: "instance.edition.artwork.artists",
      },
      {
        label: "Title",
        type: "label",
        name: "instance.edition.artwork.title",
        disabled: disabled,
      },
      {
        label: "Year",
        type: "label",
        name: "instance.edition.year",
        disabled: disabled,
      },
      {
        label: "Edition",
        type: "label",
        name: "instance.edition.number",
        disabled: disabled,
      },
      {
        label: "Released",
        type: "custom",
        name: "released",
        component: ({ values, formik }) => {
          const inputProps = {
            name: "released",
            type: "toggle",
            designType: "twoStateCheckbox",
            disabled:
              values.released === -1 ||
              disabled ||
              reservation?.categoryId === 2,
            formik,
          };
          return <>{getInput(inputProps)}</>;
        },
      },
    ],
    dataKey: "reservationInstancesV2",
    FETCH_QUERY: fetchReservationInstancesV2({
      instance: {
        edition: {
          artwork: {
            artists: {},
          },
        },
      },
      reservation: {
        createdBy: {},
        type: {},
        reservationUser: {},
      },
    }),
    UPDATE_MUTATION: RELEASE_RESERVATION_INSTANCE({
      instance: {
        edition: {
          artwork: {
            artists: {},
          },
        },
      },
      reservation: {
        createdBy: {},
        type: {},
        reservationUser: {},
      },
    }),
    variables: {
      reservationId: {
        value: reservation.id,
      },
    },
    variant: "dynamicHeight",
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full mt-10">
      <SectionHeader
        title={`${reservation.categoryId === 1 ? "Reservation" : "Make Available"} Instances`}
      />
      <div className="w-full mb-6">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export const ReservationDetails = props => {
  const { formik, reservation } = props;

  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_RESERVATION");
  const disabledInstances = !hasPermission("UPDATE_RESERVATION_INSTANCE");

  const modalProps = {
    body: AddReservationInstance,
    reservation,
    closeOnBackdrop: false,
    hideCloseButton: false,
    scale: "md",
    ...props,
  };

  const reservationActions = [
    {
      type: "black",
      label: "Add Work",
      action: () => null,
      component: () => (
        <Modal {...modalProps}>
          <Button label="Add Work" action="dark" disabled={disabledInstances} />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full mb-20">
      <SectionHeader
        title={`Edit ${reservation.categoryId === 1 ? "Reservation" : "Make Available"}`}
        actions={reservationActions}
      />
      <ReservationEdit
        formik={formik}
        reservation={reservation}
        disabled={disabled}
        submitOnBlur={true}
      />

      <ReservationInstances reservation={reservation} />
    </div>
  );
};

export default ReservationDetails;
