import { gql } from "@apollo/client";
import { Reservation as reservation } from "../fragments";

const CREATE_RESERVATION = props => gql`
  mutation CreateReservation($input: CreateReservationInput!) {
    createReservation(input: $input) {
      success
      error
      reservation {
        id
      }
    }
  }
`;

const UPDATE_RESERVATION = props => gql`
  mutation UpdateReservation($input: UpdateReservationInput!) {
    updateReservation(input: $input) {
      success
      error
      reservation {
        ${reservation(props)}
      }
    }
  }
`;

const UPDATE_RESERVATION_RELEASE_STATUS = props => gql`
  mutation UpdateReservationReleaseStatus($input: UpdateReservationReleaseStatusInput!) {
    updateReservationReleaseStatus(input: $input) {
      success
      error
      reservation {
        ${reservation(props)}
      }
    }
  }
`;

export {
  CREATE_RESERVATION,
  UPDATE_RESERVATION,
  UPDATE_RESERVATION_RELEASE_STATUS,
};
