import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useFormik } from "formik";
import { FETCH_RESERVATION as fetchReservation } from "../../../../graphql/query/Reservation";
import { UPDATE_RESERVATION as updateReservationMutation } from "../../../../graphql/mutation/Reservation";
import AuthRoute from "../../../../components/AuthRoute";
import routes from "./routes";
import { NavBar } from "../../../../components/ui";
import { Portal, getChangedFields } from "../../../../util";

const Body = props => {
  return (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...props} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const { data: { reservation } = {} } = useQuery(
    fetchReservation({
      createdBy: {},
      reservationInstance: {
        instance: {
          edition: {
            artwork: {
              artists: {},
            },
          },
        },
      },
      reservationUser: {},
      type: {},
    }),
    {
      variables: { id: +id },
    },
  );

  const [updateReservation, { loading }] = useMutation(
    updateReservationMutation({
      createdBy: {},
      reservationInstance: {},
      reservationUser: {},
      type: {},
    }),
  );

  const abortController = useRef();

  const formik = useFormik({
    initialValues: reservation ? reservation : [],
    enableReinitialize: true,
    onSubmit: values => {
      const omitKeys = (obj, arr) =>
        Object.fromEntries(
          Object.entries(obj).filter(([k]) => !arr.includes(k)),
        );

      const filteredValues = omitKeys(values, [
        "createdBy",
        "dateModified",
        "instance",
        "isnewData",
        "rawFields",
        "reservation",
        "reservationInstances",
        "reservationUser",
        "type",
        "userId",
        "updatedAt",
        "reservationInstanceCount",
        "__typename",
      ]);
      updateReservationValue(filteredValues);
    },
  });

  const updateReservationValue = values => {
    const changedFields = getChangedFields(values, reservation);

    if (Object.keys(changedFields).length > 0) {
      const controller = new AbortController();
      abortController.current = controller;

      updateReservation({
        variables: {
          input: {
            id: +id,
            ...values,
          },
        },
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (formik?.values && abortController?.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    reservation,
    formik,
    loading,
    updateReservation,
    updateReservationValue,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="m-8 mr-[80px] flex flex-1 overflow-x-auto">
        {reservation && <Body {...bodyProps} />}
      </div>
    </div>
  );
};

export default Wizard;
