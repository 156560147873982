import { FETCH_RESERVATIONS_LIST as fetchReservationsList } from "../../../../../graphql/query/Reservation";
import { reservationFilterStore } from "../../../components/useReservationFilters";

const SearchedCompanies = () => {
  const tableProps = {
    dataKey: "reservationsV2",
    FETCH_QUERY: fetchReservationsList({}),
    variables: {
      ...reservationFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedCompanies;
