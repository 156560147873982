import { Listbox, Transition, RadioGroup } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  ChevronUpDownIcon,
  PaperClipIcon,
  XMarkIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { getIn } from "formik";
import moment from "moment";
import { Fragment, useRef, useEffect, useState } from "react";
import Mask from "react-input-mask";

import { classNames } from "../../../util";

import { Button } from "../Button";
import { IconButton } from "..";
import { Spinner } from "../Spinner";

import RichText from "./components/RichText";
import MultiSelect from "./components/MultiSelect";
import SearchBar from "./components/SearchBar";
import Toggle from "./components/Toggle";
import CKEditorText from "./components/CKEditorText";
import ContactSearch from "./components/ContactSearch";
import InvoiceSearchInput from "./components/InvoiceSearch";
import InstanceSearchInput from "./components/InstanceSearch";
import ExhibitionSearchInput from "./components/ExhibitionSearch";
import ArtfairSearchInput from "./components/ArtfairSearch";
import ARR from "./components/ARR";
import ArtworkSearchInput from "./components/ArtworkSearch";
import ConsignmentSearchInput from "./components/ConsignmentSearch";
import TransactionSearchInput from "./components/TransactionSearch";

import Checkbox from "../Checkbox";
import { Modal } from "../Modal";

const useAutosizeTextArea = (textAreaRef, value, rows) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height =
        (scrollHeight > rows * 38 ? scrollHeight : rows * 36) + "px";
    }
  }, [textAreaRef, value]);
};

const Operator = props => {
  const { formik, label, name, operatorOptions, type } = props;

  const options = {
    "multi-select": [
      { label: "In", value: "in" },
      { label: "Not In", value: "notIn" },
      { label: "Is Null", value: "isNull" },
    ],
    number: [
      { label: "Is Null", value: "isNull" },
      { label: "Equal", value: "eq" },
      { label: "Not Equal", value: "ne" },
      { label: "Greater Than", value: "gt" },
      { label: "Less Than", value: "lt" },
    ],
    text: [
      { label: "Is Null", value: "isNull" },
      { label: "Equal", value: "eq" },
      { label: "Not Equal", value: "ne" },
      { label: "Like", value: "like" },
      { label: "Not Like", value: "notLike" },
    ],
    search: [
      { label: "Equal", value: "eq" },
      { label: "Not Equal", value: "ne" },
    ],
  };

  const inputProps = {
    defaultValue: "like",
    formik,
    name,
    options:
      operatorOptions ||
      options[type] ||
      (type.includes("Search") && options["search"]) ||
      options["text"],
    type: "select",
    variant: "small",
  };

  return (
    <div className="flex items-center gap-4">
      <div>{label}</div>
      <div>{getInput(inputProps)}</div>
    </div>
  );
};

const PostLabelInput = props => {
  const { formik, label, postLabelInput } = props;
  const inputProps = {
    ...postLabelInput,
    className: `flex-row-reverse ${postLabelInput?.className}`,
    formik,
  };

  return (
    <div
      className={`flex items-center gap-4 ${postLabelInput && "justify-between"}`}
    >
      <div>{label}</div>
      <div>{postLabelInput && getInput(inputProps)}</div>
    </div>
  );
};

const getInput = props => {
  const {
    allowNegativeInput = true,
    allowDecimal = true,
    formik,
    hasOperator = false,
    label,
    name: defaultName,
    submitOnBlur = false,
    submitOnChange = false,
    onChange,
    options,
    operatorOptions,
    postLabelInput,
    type,
    step = 1,
    min,
    max,
    preSubmit = () => {
      return;
    },
    title,
    emptyToZero = false,
    allowEmpty = false,
    labelClass = "",
  } = props;
  const name = hasOperator ? `${defaultName}.value` : defaultName;

  const setValue = async value => {
    formik?.getFieldHelpers(name)?.setValue(value);
    await submit();
  };

  const submit = async () => {
    await preSubmit();
    (submitOnBlur || submitOnChange) && formik?.submitForm();
  };

  const operatorProps = {
    formik,
    label,
    name: `${defaultName}.operator`,
    operatorOptions,
    type,
  };

  const inputProps = {
    ...props,
    ...formik?.getFieldProps(name),
    allowNegativeInput,
    allowDecimal,
    error: getIn(formik?.errors, name),
    label: hasOperator ? (
      <Operator {...operatorProps} />
    ) : postLabelInput ? (
      <PostLabelInput {...props} />
    ) : (
      label
    ),
    name,
    touch: getIn(formik?.touched, name),
  };

  if (
    inputProps?.value === null &&
    props.designType === "threeStateCheckbox" &&
    type === "toggle"
  ) {
    // Do nothing, value remains null for this specific case
  } else if (inputProps?.value === undefined || inputProps?.value === null) {
    inputProps.value = "";
  }

  switch (type) {
    case "arr":
      return <ARR {...inputProps} onChange={setValue} />;
    case "artworkSearch":
      return <ArtworkSearchInput {...inputProps} onChange={setValue} />;
    case "contactSearch":
      return <ContactSearch {...inputProps} onChange={setValue} />;
    case "artFairSearch":
      return <ArtfairSearchInput {...inputProps} onChange={setValue} />;
    case "checkbox":
      return (
        <Checkbox
          {...inputProps}
          submitOnChangeFunc={
            submitOnChange
              ? submit
              : () => {
                  return;
                }
          }
          onBlur={submitOnBlur ? submit : undefined}
        />
      );
    case "ckeditortext":
    default:
      return (
        <CKEditorText
          {...inputProps}
          {...(formik ? { onChange: setValue } : {})}
        />
      );
    case "consignmentSearch":
      return <ConsignmentSearchInput {...inputProps} onChange={setValue} />;
    case "date":
      // eslint-disable-next-line
      const handleBlur = e => {
        if (e?.target?.value == "") {
          e.target.value = null;
        }
        if (submitOnBlur) {
          submit();
        }
      };
      return (
        <div title={title} className={labelClass}>
          <Input
            {...inputProps}
            value={
              inputProps?.value &&
              moment(inputProps?.value).format("YYYY-MM-DD")
            }
            onBlur={handleBlur}
          />
        </div>
      );
    case "exhibitionSearch":
      return <ExhibitionSearchInput {...inputProps} onChange={setValue} />;
    case "href":
      // eslint-disable-next-line
      const hrefLink =
        typeof inputProps?.hrefLink === "function"
          ? inputProps?.hrefLink?.(inputProps)
          : inputProps?.hrefLink;
      // eslint-disable-next-line
      const href = inputProps?.value?.trim();
      // eslint-disable-next-line
      const disabled =
        typeof inputProps?.disabledHref === "function"
          ? inputProps?.disabledHref?.(inputProps)
          : inputProps?.disabledHref || false;

      // eslint-disable-next-line
      const handleClick = () => {
        if (hrefLink) {
          window.open(hrefLink, "_blank", "noreferrer");
        } else if (href) {
          const formattedHref =
            href.startsWith("http://") || href.startsWith("https://")
              ? href
              : `https://${href}`;

          window.open(formattedHref, "_blank", "noreferrer");
        }
      };

      // eslint-disable-next-line
      const postIcon = (
        <>
          <IconButton
            variant="clean"
            title="Open Link"
            onClick={() => !disabled && handleClick()}
          >
            <ArrowTopRightOnSquareIcon
              className={`h-6 w-6 cursor-pointer ${((!hrefLink && !href) || disabled) && "!cursor-default text-gray-400"}`}
            />
          </IconButton>
        </>
      );

      return (
        <Input
          {...{ ...inputProps, type: "text" }}
          onBlur={submitOnBlur ? submit : undefined}
          postIcon={postIcon}
        />
      );
    case "img":
      return (
        <UploadImage
          {...inputProps}
          {...(formik ? { onChange: setValue } : {})}
        />
      );
    case "instanceSearch":
      return <InstanceSearchInput {...inputProps} onChange={setValue} />;
    case "invoiceSearch":
      return <InvoiceSearchInput {...inputProps} onChange={setValue} />;
    case "label":
      return (
        <h5 className={`pb-4 text-xl font-bold ${inputProps?.className}`}>
          {label}
        </h5>
      );
    case "mask":
      return (
        <InputMask {...inputProps} onBlur={submitOnBlur ? submit : undefined} />
      );
    case "multi-select":
      if (
        inputProps?.appendMissingOption &&
        inputProps?.value &&
        !options?.some(option => option.value === inputProps?.value)
      ) {
        options?.push({ label: inputProps?.value, value: inputProps?.value });
      }

      return (
        <div title={title} className={labelClass}>
          <MultiSelect
            {...inputProps}
            options={
              inputProps?.isMulti
                ? options
                : inputProps?.value && options
                  ? [{ label: "Unselect", value: null }, ...options]
                  : options
            }
            selected={
              inputProps?.isMulti
                ? inputProps?.value
                : options?.find(option => option.value === inputProps.value) ||
                  null
            }
            {...(formik ? { onChange: onChange || setValue } : {})}
          />
        </div>
      );
    case "number":
      return (
        <Input
          {...inputProps}
          onBlur={e => {
            const inputValue = e.target.value;

            formik?.setFieldValue(
              name,
              inputValue ? +inputValue : allowEmpty ? null : 0,
            );
            submitOnBlur && submit();
          }}
        />
      );
    case "number-max-min":
      return (
        <Input
          {...inputProps}
          type="number"
          step={step}
          min={min}
          max={max}
          onBlur={e => {
            const inputValue = e.target.value;
            formik?.setFieldValue(name, inputValue ? +inputValue : null);
            submitOnBlur && submit();
          }}
          onChange={e => {
            let { value, min, max } = e.target;
            if (min || max) {
              value = Math.max(
                Number(min),
                Math.min(Number(max), Number(value)),
              );
              formik?.setFieldValue(name, value);
            }
            formik?.setFieldValue(name, value);
          }}
        />
      );
    case "number-currency":
      return (
        <CurrencyInput
          {...inputProps}
          onChange={value => {
            formik.setFieldValue(name, value);
          }}
          onBlur={e => {
            const inputValue = e.target.value;
            if (inputValue === "" && emptyToZero) {
              formik?.setFieldValue(name, 0);
            } else if (inputValue === "" && allowEmpty) {
              formik?.setFieldValue(name, null);
            } else {
              inputValue && formik?.setFieldValue(name, parseFloat(inputValue));
            }
            submitOnBlur && submit();
          }}
        />
      );
    case "radio":
      return (
        <Radio {...inputProps} {...(formik ? { onChange: setValue } : {})} />
      );
    case "removeDynamicInputs":
      // eslint-disable-next-line
      const deleteModalProps = {
        title: "Are you sure?",
        scale: "sm",
        description: `Would you like to remove this row?`,
        closeOnBackdrop: true,
        disabled: false,
        body: ({ closeModal }) => {
          const handleDelete = async () => {
            const initialValues = formik?.values[name];
            const removedObj = initialValues.pop();
            await formik?.setFieldValue(name, initialValues);

            const filteredValues = Object.fromEntries(
              Object.entries(formik?.values).filter(
                ([key]) => !(key in removedObj),
              ),
            );

            await formik.setValues(filteredValues);
            closeModal();
          };
          return (
            <div className="mt-4 flex justify-center">
              <Button
                className="mr-3"
                label="Cancel"
                onClick={() => {
                  closeModal();
                }}
              />
              <Button label="Remove" onClick={handleDelete} />
            </div>
          );
        },
      };
      return (
        <div className="user-list-actions flex text-right text-sm font-medium">
          {!deleteModalProps.disabled && (
            <div className="mt-6 grid grid-cols-4 gap-4">
              {
                <Modal {...deleteModalProps}>
                  <IconButton variant="clean" title="Remove Row">
                    <XMarkIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
              }
            </div>
          )}
        </div>
      );
    case "richtext":
      return (
        <RichText {...inputProps} {...(formik ? { onChange: setValue } : {})} />
      );
    case "select":
      return (
        <InputSelect
          {...inputProps}
          selected={options?.find(option => option.value === inputProps.value)}
          {...(formik ? { onChange: setValue } : {})}
        />
      );
    case "text":
      return (
        <Input {...inputProps} onBlur={submitOnBlur ? submit : undefined} />
      );
    case "text-3decimal":
      return (
        <Input
          {...{ ...inputProps, type: "text" }}
          value={inputProps?.value ? (+inputProps?.value).toFixed(3) : "0.000"}
          onBlur={submitOnBlur ? submit : undefined}
        />
      );
    case "text-currency":
      return (
        <Input
          {...{ ...inputProps, type: "text" }}
          value={
            inputProps?.value
              ? (+inputProps?.value).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0.00"
          }
          onBlur={submitOnBlur ? submit : undefined}
        />
      );
    case "textarea":
      return (
        <TextArea {...inputProps} onBlur={submitOnBlur ? submit : undefined} />
      );
    case "time":
      // eslint-disable-next-line
      let value = inputProps?.value;
      if (inputProps?.value?.includes("T")) {
        const localeTime = new Date(inputProps?.value)
          ?.toTimeString()
          ?.split(":");
        value = `${localeTime[0]}:${localeTime[1]}`;
      }
      return (
        <Input
          {...inputProps}
          value={value}
          onBlur={submitOnBlur ? submit : undefined}
        />
      );
    case "timestamp":
      return (
        <Input
          {...{ ...inputProps, type: "datetime-local" }}
          value={
            inputProps?.value &&
            moment.utc(inputProps?.value).format("YYYY-MM-DD HH:mm:ss")
          }
          onBlur={submitOnBlur ? submit : undefined}
        />
      );
    case "transactionSearch":
      return <TransactionSearchInput {...inputProps} onChange={setValue} />;
    case "toggle":
      return (
        <Toggle {...inputProps} {...(formik ? { onChange: setValue } : {})} />
      );
  }
};

const UploadImage = props => {
  const {
    accept,
    disabled,
    handleDelete,
    name,
    label,
    value,
    onChange,
    handleDetach,
    isMultiSelect = false,
    maxSize,
    uploadType = "image",
    w = "w-96",
    h = "h-32",
    text = `Browse ${uploadType} to upload`,
    className = "",
    textClassName = "",
    uploadButtonClassName = "",
    variant,
    uploadModalTitle = "",
    loading,
  } = props || {};

  const [previewImage, handlePreviewImage] = useState();

  const ref = useRef();

  const handleClick = () => {
    ref?.current?.click();
  };

  let sumSize = 0;

  if (Array.isArray(value)) {
    sumSize = (
      value?.reduce((acc, cur) => acc + cur.size, 0) /
      (1024 * 1024)
    ).toFixed(2);
  }

  const UploadModal = ({ isOpen, closeModal }) => {
    useEffect(() => {
      handlePreviewImage(null);
    }, [isOpen]);

    return (
      <div className="w-full p-4">
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col text-2xl font-bold">
            {uploadModalTitle || "Upload Image"}
          </div>
          <div className="flex flex-col">
            <div>
              <Button
                action="default"
                className="mr-8"
                label="Cancel"
                onClick={() => {
                  closeModal();
                }}
              />
              <Button
                label={loading ? "Uploading" : "Upload"}
                disabled={loading || !previewImage}
                onClick={() => onChange(ref?.current, closeModal)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center pb-3 pt-8">
          {uploader(true, "w-[400px]", "h-[400px]")}
        </div>
      </div>
    );
  };

  const deleteProfileImageModalProps = {
    title: "Delete Image",
    scale: "sm",
    description: "Are you sure you want to delete this image?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const deleteProfileImage = () => handleDelete();

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={deleteProfileImage}
          />
        </div>
      );
    },
  };

  const uploadPreview = () => {
    const file = ref?.current?.files[0];
    const url = URL.createObjectURL(file);
    handlePreviewImage(url);
  };

  const uploadModalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    body: UploadModal,
  };

  const uploadButton = (
    <Button
      className={`z-10 ${uploadButtonClassName}`}
      label={"Upload"}
      onClick={handleClick}
    />
  );

  const variants = {
    default: {
      withAction: true,
      actionContent: uploadButton,
    },
    modal: {
      withAction: false,
      actionContent: (
        <div className="z-20 flex flex-col items-center gap-4">
          {!disabled && (
            <>
              <Modal {...uploadModalProps}>
                <Button
                  className={`${uploadButtonClassName}`}
                  label={"Upload"}
                  anchorType={true}
                />
              </Modal>
              {handleDelete && value && (
                <Modal {...deleteProfileImageModalProps}>
                  <Button label={"Delete"} />
                </Modal>
              )}
            </>
          )}
        </div>
      ),
    },
  };
  const variantProps = variants[variant] || variants.default;
  const uploader = (uploaderActions, customW, customH) => {
    const wh = `${customW || w} ${customH || h}`;
    const modalPreview = uploaderActions && previewImage;
    return (
      <div
        className={`group relative ${wh} flex-center flex items-center justify-center overflow-hidden border border-black ${className}`}
      >
        {(loading && variantProps.withAction) ||
        (uploaderActions && loading) ? (
          <Spinner className="absolute" />
        ) : modalPreview || (!isMultiSelect && value) ? (
          <img
            src={modalPreview ? previewImage : value}
            className="h-auto w-full"
          />
        ) : (
          !disabled && (
            <span className={`text-sm text-gray-700 ${textClassName}`}>
              {text}
            </span>
          )
        )}
        <div
          className={`opacity-0 ${!disabled ? "cursor-pointer group-hover:opacity-100" : "cursor-default"} absolute inset-0 flex items-center justify-center`}
        >
          <div
            className={`${
              loading ? "opacity-0" : "opacity-70"
            } absolute inset-0 bg-gray-300`}
          />
          <div
            className={`z-10 ${loading ? "pointer-events-none opacity-0" : ""}`}
          >
            {uploaderActions ? uploadButton : variantProps.actionContent}
          </div>
          {(uploaderActions || variantProps.withAction) && (
            <input
              ref={ref}
              type="file"
              className="hidden"
              accept={accept ? accept : "*"}
              onChange={uploaderActions ? uploadPreview : onChange}
              multiple={`${isMultiSelect ? "multiple" : ""}`}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col">
      <label htmlFor={name} className="block text-xs font-medium text-black">
        {label}
      </label>
      {uploader()}
      {value && isMultiSelect && (
        <div className="mb-4 mt-4">
          {value?.map((attachment, index) => {
            return (
              <div
                className="flex justify-between px-2 py-1 text-sm"
                key={index}
              >
                <span>{(attachment?.size / (1024 * 1024)).toFixed(2)} MB</span>
                <div className="flex">
                  <PaperClipIcon className="mr-3 inline h-4 w-4" />
                  {attachment?.filename?.split("/").pop()}
                  <XMarkIcon
                    className="ml-8 inline h-4 w-4 cursor-pointer"
                    onClick={() => handleDetach(attachment)}
                  ></XMarkIcon>
                </div>
              </div>
            );
          })}
          {maxSize && (
            <div className="mt-2">
              <IconButton
                title={
                  sumSize > maxSize
                    ? `Max size should be ${maxSize}MB`
                    : `Upto ${maxSize}MB supported`
                }
                className="border-none hover:bg-transparent"
              >
                {sumSize > maxSize ? (
                  <ExclamationTriangleIcon className="mr-4 inline h-5 w-5 text-red-600" />
                ) : (
                  <CheckIcon className="mr-4 inline h-5 w-5 text-green-600" />
                )}
              </IconButton>
              <span>{`${sumSize} size of all attachments (${maxSize} MB max)`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const TextArea = props => {
  const {
    error,
    label,
    subLabel,
    name,
    onChange,
    placeholder,
    type,
    value,
    bottomLabel,
    variant,
    onBlur,
    inputClassName,
    rows = 3,
    autoHeight,
    disabled = false,
    readOnly = false,
  } = props;

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, value || "", rows);

  const inputProps = {
    name,
    onChange,
    placeholder,
    type,
    value: value || "",
    rows,
    disabled,
    readOnly,
    onBlur,
  };

  if (autoHeight) {
    inputProps.ref = textAreaRef;
  }

  const variants = {
    default: {
      container: "flex flex-col flex-grow",
      label: "block text-xs font-medium text-black",
      inputContainer: "mt-1",
      input: `${inputClassName} ${disabled ? "!bg-white !text-gray-400 !border-gray-400" : ""} focus:ring-0 focus:border-black block w-full sm:text-sm border-black rounded`,
    },
    simpleRight: {
      container: "flex flex-col flex-grow",
      label: `flex flex-row text-xs font-medium text-black ${
        bottomLabel &&
        "order-2 font-bold pt-2 text-right capitalise w-full justify-end"
      }`,
      subLabel: `flex flex-column flex-1 text-left font-light`,
      inputContainer: "flex mt-1",
      input: `${inputClassName} ${disabled ? "!bg-white !text-gray-400 !border-gray-400" : ""} w-full border-0 border-b border-black focus:border-black focus:ring-0 pl-0`,
    },
  };

  const classes = variants[variant] || variants.default;

  return (
    <div className={classes.container}>
      <label htmlFor={name} className={classes.label}>
        {subLabel && <span className={classes.subLabel}>{subLabel}</span>}
        <span>{label}</span>
      </label>
      <div className={classes.inputContainer}>
        <textarea {...inputProps} className={classes.input} />
      </div>
      <span className="text-sm text-red-600">{error}</span>
    </div>
  );
};

const Input = props => {
  const {
    preIcon,
    postIcon,
    label,
    labelleft,
    labelleftColor = "black",
    bottomLabel,
    rightLabel,
    name,
    onChange,
    onInput,
    placeholder,
    type,
    value,
    onBlur,
    onFocus,
    variant = "default",
    className,
    disabled = false,
    error = "",
    inputClassName = "",
    step,
    min,
    max,
    hasActions,
    actionComponent,
    inputBgClass,
  } = props;

  const variants = {
    overlap: {
      container: "relative border border-black rounded px-3 py-2",
      label:
        "absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-black",
      input: `${
        disabled
          ? `${inputBgClass ?? "!bg-white"} !text-gray-400 !border-gray-400`
          : ""
      } block w-full border-0 p-0 text-black placeholder-gray-500 sm:text-sm leading-5 text-lg focus:border-black focus:ring-0 remove-arrow`,
      inputContainer: `flex mt-1 ${hasActions ? "relative" : ""}`,
      errorMessage: "text-red-600 text-sm",
    },
    default: {
      container: "flex flex-col flex-1",
      label: "block text-xs font-medium text-black",
      input: `${disabled ? `${inputBgClass ?? "!bg-white"} !text-gray-400 !border-gray-400` : ""} ${
        preIcon ? "rounded-none rounded-r" : "rounded"
      } ${inputClassName} block w-full ${!postIcon ? "border-black" : "border-0"} leading-5 focus:border-black focus:ring-0 remove-arrow`,
      inputContainer: `flex mt-1 items-center ${hasActions ? "relative" : ""} ${postIcon && "border border-black rounded"}`,
      errorMessage: "text-red-600 text-sm",
    },
    horizontal: {
      container:
        "flex flex-row items-left grid grid-cols-5 gap-x-5 items-center",
      label: "block sm13 font-medium text-black col-span-1 text-right",
      input: `${disabled ? `${inputBgClass ?? "!bg-white"} !text-gray-400 !border-gray-400` : ""} ${
        preIcon ? "rounded-none rounded-r" : "rounded"
      } border-none font-light text-lightgrey remove-arrow`,
      inputContainer: `flex mt-1 col-span-3 ${hasActions ? "relative" : ""}`,
      errorMessage: "text-red-600 text-sm col-start-2 col-span-3",
    },
    simple: {
      container: "flex flex-col flex-1",
      label: `block text-xs font-medium text-black ${
        bottomLabel && "order-2 font-bold pt-2"
      }`,
      input: `${
        disabled
          ? `${inputBgClass ?? "!bg-white"} !text-gray-400 !border-gray-400`
          : ""
      } ${inputClassName} border-0 border-b border-black focus:border-black focus:ring-0 pl-0 remove-arrow`,
      inputContainer: `flex mt-1 ${hasActions ? "relative" : ""}`,
      errorMessage: "text-red-600 text-sm",
    },
    simpleRight: {
      container: "flex flex-col flex-1",
      label: `block text-xs font-medium text-black ${
        bottomLabel && "order-2 font-bold pt-2 text-right"
      }`,
      input: `${
        disabled
          ? `${inputBgClass ?? "!bg-white"} !text-gray-400 !border-gray-400`
          : ""
      } ${inputClassName} w-full border-0 border-b border-black focus:border-black focus:ring-0 pl-0`,
      inputContainer: `flex mt-1 ${hasActions ? "relative" : ""} remove-arrow`,
      errorMessage: "text-red-600 text-sm",
    },
  };
  const classes = variants[variant] || variants.default;

  const inputProps = {
    value: value !== null || value !== undefined ? value : "",
    type,
    name,
    placeholder,
    className: classes.input,
    onBlur,
    onChange,
    onInput,
    disabled,
    onFocus,
    step,
    min,
    max,
  };

  return (
    <div className={`${classes.container} ${className}`}>
      <label htmlFor={name} className={classes.label}>
        {labelleft ? (
          <div className="flex justify-between">
            <div className={`text-${labelleftColor}`}>{labelleft}</div>
            <div>{label}</div>
          </div>
        ) : (
          <div
            className={`${
              rightLabel && "flex flex-row-reverse space-x-4 space-x-reverse"
            }`}
          >
            {label}
          </div>
        )}
      </label>
      <div className={`${bottomLabel && "order-1"} ${classes.inputContainer}`}>
        {preIcon && (
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-black px-2 text-white sm:text-sm">
            {preIcon}
          </span>
        )}
        <input {...inputProps} />
        {postIcon && (
          <span className="inline-flex items-center pr-2">{postIcon}</span>
        )}
        {hasActions && (
          <div className="absolute right-1 top-2">{actionComponent}</div>
        )}
      </div>
      {error && <small className={classes.errorMessage}>{error}</small>}
    </div>
  );
};

const CurrencyInput = props => {
  const [currency, setCurrency] = useState();
  const allowDecimal = props?.allowDecimal || false;
  const allowNegativeInput = props?.allowNegativeInput || false;

  useEffect(() => {
    setCurrency(props.value);
  }, [props.value]);

  const handleInputChange = event => {
    let newValue = event.target.value;
    if (newValue !== "") {
      if (allowDecimal) {
        const valParts = newValue.split(".");
        if (valParts.length > 1 && valParts[1].length > 2) {
          newValue = parseFloat(newValue).toFixed(2);
        }
      } else {
        newValue = parseInt(newValue);
      }
      if (!allowNegativeInput) {
        newValue = Math.abs(newValue);
      }
    }
    setCurrency(newValue);
  };

  const handleInputBlur = event => {
    props.onBlur(event);
  };

  return (
    <Input
      {...props}
      type="number"
      value={currency}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
    />
  );
};

const InputSelect = props => {
  const {
    className = "",
    disabled = false,
    error,
    selected,
    onChange,
    options = [],
    label,
    variant = "default",
    bottomLabel,
    placeholder,
    rightLabel,
    hideError = false,
    selectedTextColor = "text-black",
  } = props || {};

  const variants = {
    default: {
      label: "block text-xs font-medium text-black ",
      field: "mt-1 relative",
      button: `bg-white relative w-full border border-black rounded pl-3 pr-10 py-2 text-left cursor-default sm:text-sm ${selectedTextColor}`,
    },
    small: {
      label: "block text-xs font-medium text-black ",
      field: "mt-1 relative",
      button: `bg-white relative w-full border border-black rounded pl-3 pr-10 text-left cursor-default sm:text-sm ${selectedTextColor}`,
    },
    simple: {
      label: `block text-xs font-medium text-black ${
        bottomLabel && "order-2 font-bold pt-2"
      }`,
      field: `mt-1 relative ${bottomLabel && "order-1"}`,
      button: `relative w-full pr-10 py-2 text-left cursor-default border-b border-black ${selectedTextColor}`,
    },
    simpleRight: {
      label: `block text-xs font-medium text-black ${
        bottomLabel && "order-2 font-bold pt-2 text-right capitalise"
      }`,
      field: `mt-1 relative ${bottomLabel && "order-1"}`,
      button: `relative w-full pr-10 py-2 text-left cursor-default border-b border-black ${selectedTextColor}`,
    },
    horizontal: {
      label: "col-span-1 flex-1 sm13 font-medium text-right",
      field: "col-span-2 ml-3 relative flex-1 min-w-max",
      container: "flex items-center grid grid-cols-5 gap-5",
      button: `relative text-left pr-12 ${selectedTextColor}`,
    },
  };

  const classes = variants[variant] || variants.default;

  return (
    <Listbox
      disabled={disabled}
      value={selected?.value}
      onChange={onChange}
      className={`${classes?.container} ${disabled ? "opacity-50" : ""}`}
    >
      {({ open }) => (
        <div className={`${bottomLabel && "flex flex-col"} ${className}`}>
          <Listbox.Label
            className={`${classes?.label} ${rightLabel && "text-right"}`}
          >
            {label}
          </Listbox.Label>
          <div className={classes?.field}>
            <Listbox.Button className={classes.button}>
              <span className="block truncate">
                {selected?.label || placeholder || "All"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-black"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded border border-black bg-white py-1 text-base focus:outline-none sm:text-sm">
                {options?.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    disabled={item?.disabled}
                    className={({ active, disabled }) =>
                      classNames(
                        disabled ? "bg-gray-300" : "",
                        active ? "bg-gray-100 text-black" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={item?.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}
                        >
                          {item?.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              "absolute inset-y-0 right-0 flex items-center pr-4 text-black",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {error && !hideError && (
            <Listbox.Label
              className={`text-sm text-red-600 ${rightLabel && "text-left"}`}
            >
              {error}
            </Listbox.Label>
          )}
        </div>
      )}
    </Listbox>
  );
};

const Radio = props => {
  const {
    label,
    options = [],
    value,
    onChange,
    variant = "default",
  } = props || {};

  const variants = {
    default: {
      label: "block text-xs font-medium text-black",
      field: "mt-2 relative",
    },
    horizontal: {
      label: "col-span-1 flex-1 sm13 font-medium text-right",
      field: "mt-2 relative flex flex-row justify-between min-w-max",
    },
  };
  const classes = variants[variant] || variants.default;

  return (
    <RadioGroup value={value} onChange={onChange}>
      {label && (
        <RadioGroup.Label className={classes?.label}>{label}</RadioGroup.Label>
      )}
      <div className={classes?.field}>
        {options?.map((item, index) => (
          <RadioGroup.Option
            key={index}
            value={item.value}
            className="cursor-pointer"
          >
            {({ checked }) =>
              checked ? (
                <div className="mb-2 flex items-center">
                  <span className="mr-3 inline-block h-5 w-5 rounded-full border-2 border-black">
                    <CheckIcon className="h-4 w-4" />
                  </span>
                  <span className="text-xs">{item?.label}</span>
                </div>
              ) : (
                <div className="mb-2 flex items-center">
                  <span className="mr-3 inline-block h-5 w-5 rounded-full border-2 border-gray-400" />
                  <span className="text-xs">{item?.label}</span>
                </div>
              )
            }
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

const InputMask = props => {
  const {
    preIcon,
    label,
    labelleft,
    labelleftColor = "black",
    bottomLabel,
    name,
    onChange,
    placeholder,
    type,
    value,
    onBlur,
    variant = "default",
    className,
    disabled = false,
    error = "",
    inputClassName = "",
    mask,
  } = props;

  const variants = {
    overlap: {
      container: "relative border border-black rounded px-3 py-2",
      label:
        "absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-black",
      input:
        "block w-full border-0 p-0 text-black placeholder-gray-500 sm:text-sm leading-5 text-lg focus:border-black focus:ring-0",
      inputContainer: "flex mt-1",
    },
    default: {
      container: "flex flex-col flex-1",
      label: "block text-xs font-medium text-black",
      input: `${
        preIcon ? "rounded-none rounded-r" : "rounded"
      } block w-full border-black leading-5 focus:border-black focus:ring-0`,
      inputContainer: "flex mt-1",
    },
    horizontal: {
      container: "flex flex-row items-left grid grid-cols-5 gap-5 items-center",
      label: "block sm13 font-medium text-black col-span-1 text-right",
      input: `${
        preIcon ? "rounded-none rounded-r" : "rounded"
      } border-none font-light text-lightgrey w-full`,
      inputContainer: "flex mt-1 col-span-3",
    },
    simple: {
      container: "flex flex-col flex-1",
      label: `block text-xs font-medium text-black ${
        bottomLabel && "order-2 font-bold pt-2"
      }`,
      input: `${inputClassName} border-0 border-b border-black focus:border-black focus:ring-0 pl-0`,
      inputContainer: "flex mt-1",
    },
  };
  const classes = variants[variant] || variants.default;

  const inputProps = {
    value: value || "",
    type,
    name,
    placeholder,
    className: classes.input,
    onChange,
    disabled,
    mask,
    onBlur,
  };

  return (
    <div className={`${classes.container} ${className}`}>
      <label htmlFor={name} className={classes.label}>
        {labelleft ? (
          <div className="flex justify-between">
            <div className={`text-${labelleftColor}`}>{labelleft}</div>
            <div>{label}</div>
          </div>
        ) : (
          <div>{label}</div>
        )}
      </label>
      <div className={`${bottomLabel && "order-1"} ${classes.inputContainer}`}>
        {preIcon && (
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-black px-2 text-white sm:text-sm">
            {preIcon}
          </span>
        )}
        <Mask
          style={{ outline: "none", marginTop: "10px" }}
          mask={mask}
          {...inputProps}
        />
      </div>
      {error && <small className="text-sm text-red-600">{error}</small>}
    </div>
  );
};

export { SearchBar, Input, getInput, InputSelect };
