import { GridInputs } from "../../../../../components/Functional";

const Instances = props => {
  const { formik } = props || {};

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Ref",
            name: "_instance.ref",
            type: "text",
            operator: "eq",
          },
        ],
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <span className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Instance</h2>
      </span>
      <GridInputs {...gridInputProps} />
    </div>
  );
};

export default Instances;
