import { GridInputs } from "../../../../../../components/Functional";
import { Button, Modal, getInput } from "../../../../../../components/ui";
import useUser from "../../../../../../hooks/useUser";
import SectionHeader from "../../components/SectionHeader";
import ManageImage from "./components/ManageImage";
import Information from "./components/information";

const WarehouseItemDetail = props => {
  const {
    calculatedDimension,
    formik,
    updateError,
    updateLoading,
    updateWarehouseItem,
    warehouseItemId,
    warehouseItem,
    showBondedWarning,
    setItemIsBonded,
    setBondedWarningCount,
  } = props || {};
  const { hasPermission } = useUser();

  const disableConfig = {
    disabled: true,
    inputClassName: "!bg-white !text-gray-400 !border-gray-400",
  };

  const inputs = {
    className: "grid grid-cols-1 gap-4",
    inputs: [
      {
        label: "Item Description",
        name: "itemDescription",
        rows: 3,
        type: "textarea",
        submitOnBlur: true,
      },
      {
        label: "Artwork Caption",
        name: "description",
        rows: 3,
        type: "textarea",
        submitOnBlur: true,
      },
      { label: "Link", name: "link", type: "href", submitOnBlur: true },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const destroyInput = {
    name: "destroyed",
    type: "checkbox",
    singleCheckbox: true,
    submitOnBlur: true,
    options: [{ label: "Destroyed", value: -1 }],
    value: warehouseItem?.destroyed,
  };
  const noteInput = {
    label: "Notes",
    name: "notes",
    rows: 14,
    type: "textarea",
    submitOnBlur: true,
  };

  const noteInputProps = {
    formik,
    ...noteInput,
  };

  const destroyModalProps = {
    title: updateError ? "Error" : "Destroy Warehouse Item",
    scale: "sm",
    description: updateError
      ? updateError
      : `Would you like to destroy this warehouse item?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDestroy = () => {
        updateWarehouseItem({
          variables: {
            input: { id: warehouseItemId, destroyed: -1 },
          },
          update: (cache, { data }) => {
            const { updateWarehouseItem: { success } = {} } = data;
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="flex mt-4">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          {!updateError && (
            <Button
              label={updateLoading ? "Destroying" : "Destroy"}
              disabled={updateLoading}
              onClick={handleDestroy}
            />
          )}
        </div>
      );
    },
  };

  const BondedWarningModalProps = {
    title: "",
    scale: "sm",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      return (
        <>
          <div className={`mb-4 text-lg`}>Customs Control</div>
          <div className="text-sm font-normal">
            This work is under customs control, you should add this fine art
            item to the import shipment
          </div>
          <div className={`mt-4 flex justify-end`}>
            <Button
              className={`!w-22`}
              action="default"
              label="OK"
              onClick={() => {
                closeModal?.();
                setItemIsBonded(false);
                setBondedWarningCount(1);
              }}
            />
          </div>
        </>
      );
    },
  };

  const manageImageProps = {
    disabled: !hasPermission("UPDATE_WAREHOUSE_ITEM"),
    formik,
    updateLoading,
    updateWarehouseItem,
    warehouseItem,
  };

  return (
    <div className="w-full">
      <Modal isOpen={showBondedWarning} {...BondedWarningModalProps} />
      <SectionHeader title={<div>Storage # {warehouseItemId}</div>} />

      <div className="w-full px-8 py-4">
        <div className="flex flex-col space-y-16">
          <div className="grid grid-cols-2 gap-8">
            <div className="grid grid-cols-2 gap-8">
              <ManageImage {...manageImageProps} />
              <div>
                <div className="mb-4">
                  {[0, null].includes(warehouseItem?.destroyed) ? (
                    <Modal {...destroyModalProps}>
                      {getInput(destroyInput)}
                    </Modal>
                  ) : (
                    <>{getInput({ ...destroyInput, formik })}</>
                  )}
                </div>

                <GridInputs {...inputProps} />
              </div>
            </div>

            <div>{getInput(noteInputProps)}</div>
          </div>
          <hr />
          <Information
            calculatedDimension={calculatedDimension}
            formik={formik}
            disableConfig={disableConfig}
            submitOnBlur={true}
            warehouseItem={warehouseItem}
          />
        </div>
      </div>
    </div>
  );
};

export default WarehouseItemDetail;
