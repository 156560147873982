import { useMutation, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FETCH_RESERVATIONS_LIST as fetchReservationList } from "../../../../graphql/query/Reservation";
import { CREATE_RESERVATION as createReservationMutation } from "../../../../graphql/mutation/Reservation";
import { FETCH_RESERVATION_TYPES } from "../../../../graphql/query/ReservationType";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { Button, Spinner } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";
import useUser from "../../../../hooks/useUser";
import * as Yup from "yup";
import { useEffect } from "react";

const NewReservation = props => {
  const { closeModal, isCreateOpen = false } = props || {};
  const navigate = useNavigate();
  const [createReservation, { loading }] = useMutation(
    createReservationMutation(),
    {
      refetchQueries: [fetchReservationList({})],
    },
  );

  const [
    fetchReservationTypes,
    { loading: reservationTypesLoading, data: { reservationTypes = [] } = {} },
  ] = useLazyQuery(FETCH_RESERVATION_TYPES);

  const [
    fetchEntities,
    { loading: entitiesLoading, data: { entities = {} } = {} },
  ] = useLazyQuery(FETCH_ENTITIES);

  useEffect(() => {
    if (isCreateOpen) {
      fetchReservationTypes();
      fetchEntities();
    }
  }, [isCreateOpen]);

  const { user } = useUser();

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      typeId: Yup.number().required("Please select a type"),
      date: Yup.date()
        .required("Please enter a date")
        .typeError("Please enter a date"),
      reservationUserId: Yup.number().required("Please select a user"),
      dateDue: Yup.date()
        .required("Please enter a date")
        .typeError("Please enter a date"),
      description: Yup.string().required("Please enter a description"),
    }),
    onSubmit: async values => {
      createReservation({
        variables: {
          input: {
            createdById: user?.entityId,
            categoryId: 1,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createReservation: { success, reservation } = {} },
        } = resp || {};
        if (success) {
          navigate(`/reservations/${reservation?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const inputs = {
    className: "grid grid-cols-1 gap-4 mt-5 w-full",
    inputs: [
      {
        className: "grid gap-4 grid-cols-2",
        inputs: [
          {
            label: "Type *",
            name: "typeId",
            type: "multi-select",
            options: reservationTypes.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "Date",
            name: "date",
            type: "date",
          },
          {
            label: "Reserved For *",
            name: "reservationUserId",
            type: "multi-select",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "End Of Reservation",
            name: "dateDue",
            type: "date",
          },
        ],
      },
      {
        className: "",
        inputs: [
          {
            label: "Description",
            name: "description",
            type: "textarea",
            inputClassName: "col-span-2",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new reservation</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>

      {reservationTypesLoading || entitiesLoading ? (
        <div className="flex h-[30dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <GridInputs {...inputProps} />
      )}
    </div>
  );
};

export default NewReservation;
