import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";

import Actions from "./components/Actions";
import Header from "./components/Header";
import SearchedReservations from "./components/SearchedReservations";

import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";
import { reservationFilterStore } from "../../components/useReservationFilters";

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();
  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      // fetch Reservation_working list
      input: { typeId: 26 },
    },
  });
  const workingList = edges?.length > 0 ? edges[0] : null;
  const workingListId = workingList?.id;
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");
  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Reservations",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    listId,
    onSuccess: () => formik?.resetForm(),
    search: reservationFilterStore.get(),
    workingListId,
  };

  const handleChange = value => {
    formik?.resetForm();
    navigate(
      `/reservations?status=${value}${listId ? `&listId=${listId}` : ""}${workingListId ? `&workingListId=${workingListId}` : ""}`,
    );
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/reservations?status=selectListWorking${listId ? `&listId=${listId}` : ""}`,
        )
      ),
      typeId: 26,
    };

    return (
      <>
        <SelectList.CreateList {...createListProps} />
      </>
    );
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      {
        actions: GeneralTabActions,
        label: "All Reservations",
        name: "all",
        tableProps: SearchedReservations(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps(actionProps),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps(actionProps),
      },
    ],
  };

  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  // function fixes header.name for select lists when status = selectListWorking
  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          header?.name && status === "selectListWorking"
            ? Array.isArray(header?.name)
              ? header?.name?.map(item => `item.${item}`)
              : `item.${header?.name}`
            : header?.name,
      };
    });

  const ReservationCategory = ({ values }) => {
    const { categoryId } = values;
    return <div>{categoryId === 2 ? "Make Available" : "Reservation"}</div>;
  };

  const tableProps = {
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        {
          label: "ID",
          name: "id",
          rowClass: "box-border",
          type: "label",
        },
        {
          label: "Category",
          name: "categoryId",
          type: "custom",
          component: ReservationCategory,
        },
        {
          label: "Type",
          name: "type.description",
          rowClass: "min-w-[320px] box-border",
          type: "label",
        },
        {
          label: "Date",
          name: "date",
          type: "date",
        },
        {
          label: "End of Reservation",
          name: "dateDue",
          type: "date",
        },
        {
          label: "Reserved For",
          name: "reservationUser.name",
          type: "label",
        },
        {
          label: "Created By",
          name: "createdBy.name",
          type: "label",
        },
        {
          label: "# of Works",
          name: "reservationInstanceCount",
          type: "label",
        },
        { label: "", type: "custom", component: Actions },
      ],
    }),
    tabProps,
    variant: "dynamicHeight",
    ...tabTableProps,
  };

  return (
    <>
      <div className="px-8">
        <Header
          leftText="All Reservations"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
