import { gql } from "@apollo/client";
import { ReservationInstance as reservationInstance } from "../fragments";

const RELEASE_RESERVATION_INSTANCE = props => gql`
  mutation ReleaseReservationInstance($input: ReleaseReservationInstanceInput!) {
    releaseReservationInstance(input: $input) {
      success
      error
      reservationInstance {
        ${reservationInstance(props)}
      }
    }
  }
`;

const CREATE_RESERVATION_INSTANCE = props => gql`
  mutation CreateReservationInstance($input: createReservationInstanceInput!) {
    createReservationInstance(input: $input) {
      success
      error
      reservationInstance {
        ${reservationInstance(props)}
      }
    }
  }
`;

export { CREATE_RESERVATION_INSTANCE, RELEASE_RESERVATION_INSTANCE };
