import { useQuery } from "@apollo/client";

import { getInput } from "../../../../../../components/ui";
import { FETCH_WAREHOUSE_SITES } from "../../../../../../graphql/query/WarehouseSite";
import ContactAddress from "../Details/components/ContactAddress";

const Details = props => {
  const { formik } = props || {};
  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );

  const inputs = [
    {
      label: "VAT",
      name: "vat",
      type: "text",
      submitOnBlur: true,
    },
    {
      label: "Sage Account",
      name: "sagecode",
      type: "text",
      submitOnBlur: true,
    },
    {
      label: "Site",
      name: "siteId",
      options:
        warehouseSites?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) || [],
      placeholder: "Select a site",
      type: "multi-select",
      submitOnBlur: true,
    },
    {
      label: "EORI",
      name: "eori",
      type: "text",
      submitOnBlur: true,
    },
    {
      label: "Artist(s)",
      name: "artistIDs",
      type: "text",
      disabled: true,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="mr-20 w-full max-w-[400px]">
      <div className="mb-4 text-xl font-bold">Details</div>
      <div className="grid gap-4">{renderInputs}</div>
    </div>
  );
};

const RecordMetadata = props => {
  const { formik } = props || {};

  const inputs = [
    {
      label: "Created",
      name: "dateEntered",
      type: "timestamp",
      disabled: true,
    },
    {
      name: "userEntered",
      type: "text",
      disabled: true,
    },
    {
      label: "Modified",
      name: "updatedAt",
      type: "timestamp",
      disabled: true,
    },
    {
      name: "user",
      type: "text",
      disabled: true,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="mr-20 w-full max-w-[400px]">
      <div className="mb-4 text-xl font-bold">Record Metadata</div>
      <div className="grid gap-4">{renderInputs}</div>
    </div>
  );
};

const BillingDelivery = props => {
  const { contact, formik } = props || {};

  // filter out and show billing and delivery address only
  const contactAddresses = contact?.contactAddresses?.filter(
    item => item?.use_billing === -1 || item?.use_delivery === -1,
  );

  return (
    <div className="container mx-auto mb-20">
      <div className="mb-8 text-2xl font-bold">Delivery & Billing</div>
      <div className="flex w-full flex-row">
        <div className="mr-20 w-full max-w-[400px]">
          <ContactAddress contact={{ ...contact, contactAddresses }} />
        </div>
        <Details formik={formik} />
        <RecordMetadata formik={formik} />
      </div>
    </div>
  );
};

export default BillingDelivery;
