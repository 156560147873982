import { gql } from "@apollo/client";

const FETCH_RESERVATION_TYPE = gql`
  query FetchReservationType($id: Int) {
    reservationType(id: $id) {
      id
      description
    }
  }
`;

const FETCH_RESERVATION_TYPES = gql`
  query FetchReservationTypes {
    reservationTypes {
      id
      description
    }
  }
`;

export { FETCH_RESERVATION_TYPE, FETCH_RESERVATION_TYPES };
