import { useMutation } from "@apollo/client";
import { CREATE_RESERVATION_INSTANCE } from "../../../../../../../graphql/mutation/ReservationInstance";
import { useFormik } from "formik";
import { Button } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_RESERVATION_INSTANCES_V2 as fetchReservationInstancesV2 } from "../../../../../../../graphql/query/ReservationInstance";

const AddReservationInstanceModal = props => {
  const { closeModal, reservation } = props || {};

  const [createReservationInstance, { loading }] = useMutation(
    CREATE_RESERVATION_INSTANCE({
      instance: {
        edition: {
          artwork: {
            artists: {},
          },
        },
      },
      reservation: {
        reservationInstance: {},
        reservationUser: {},
        type: {},
      },
    }),
    {
      refetchQueries: [
        fetchReservationInstancesV2({
          instance: {
            edition: {
              artwork: {
                artists: {},
              },
            },
          },
          reservation: {
            createdBy: {},
            type: {},
            reservationUser: {},
          },
        }),
      ],
    },
  );

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: async values => {
      createReservationInstance({
        variables: {
          input: {
            reservationId: reservation.id,
            categoryId: reservation.categoryId,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createReservationInstance: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const inputs = [
    {
      label: "Instance",
      name: "instanceId",
      type: "instanceSearch",
      filterInput: [
        {
          statusId: {
            operator: "in",
            value: [reservation?.categoryId === 2 ? 14 : 1],
          },
        },
      ],
    },
  ];

  const inputProps = {
    formik,
    inputs,
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-row">
        <div className="flex flex-col flex-1 text-2xl font-bold">
          <div>
            Add instances to{" "}
            {reservation.categoryId === 1 ? "Reservation" : "Make Available"}
          </div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default AddReservationInstanceModal;
