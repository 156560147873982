import { ClipboardIcon } from "@heroicons/react/24/outline";

import Details from "./pages/Details";

const routes = [
  {
    element: Details,
    href: "details",
    icon: ClipboardIcon,
    name: "Reservation Details",
  },
];

export default routes;
