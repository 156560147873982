import { gql } from "@apollo/client";
import { Reservation as reservation } from "../fragments";

const FETCH_RESERVATION = props => gql`
  query FetchReservation($id: Int) {
    reservation(id: $id) {
      ${reservation(props)}
    }
  }
`;

const FETCH_RESERVATIONS_V2 = props => gql`
  query FetchReservationV2($input: AdvReservationFilterInput!) {
    reservationsV2(input: $input) {
      count
      edges {
        ${reservation(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_RESERVATIONS_LIST = props => gql`
  query FetchReservationList($input: AdvReservationFilterInput!) {
    reservationsV2(input: $input) {
      count
      edges {
        id
        categoryId
        type {
          description
        }
        date
        dateDue
        reservationUser {
          name
        }
        createdBy {
          name
        }
        reservationInstanceCount
      }
      sqlQuery
    }
  }
`;

export { FETCH_RESERVATION, FETCH_RESERVATIONS_V2, FETCH_RESERVATIONS_LIST };
