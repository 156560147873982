import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, MenuButton, Modal, Button } from "../../../components/ui";
import { FETCH_RESERVATION as fetchReservation } from "../../../graphql/query/Reservation";
import routes from "../pages/Wizard/routes";
import NewReservation from "./Actions/NewReservation";
import ReservationSearch from "./ReservationSearch";
import useUser from "../../../hooks/useUser";
import { useState } from "react";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const id = pathnames?.length > 2 ? pathnames[2] : null;

  const { hasPermission } = useUser();

  const { data: { reservation } = {} } = useQuery(
    fetchReservation({ createdBy: {}, type: {}, reservationUser: {} }),
    {
      skip: !id,
      variables: { id: +id },
    },
  );

  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );

  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;

  const statusMap = {
    all: "All Reservations",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Reservations";
  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/reservations?status=${status}`) },
    ...(reservation
      ? [
          {
            label: reservation?.id,
            onClick: () => navigate(`${reservation?.id}/details`),
          },
          {
            label:
              reservation && reservation.categoryId === 2
                ? "Make Available Details"
                : route?.name,
            onClick: () => navigate(`${reservation?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids: ids.length ? ids : [+id],
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    select,
  };

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Reservation",
        disabled: !hasPermission("CREATE_RESERVATION"),
        modalProps: {
          body: NewReservation,
          disabled: !hasPermission("CREATE_RESERVATION"),
          isCreateOpen,
          onOpen: () => {
            setIsCreateOpen(true);
          },
          onClose: () => {
            setIsCreateOpen(false);
          },
          ...defaultActionProps,
        },
      },
    ],
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchModalProps = {
    body: ReservationSearch,
    closeOnBackdrop: true,
    scale: "md",
    isSearchOpen,
    onOpen: () => {
      setIsSearchOpen(true);
    },
    onClose: () => {
      setIsSearchOpen(false);
    },
  };

  return (
    <div className="flex items-center justify-between px-8 py-5 border-b">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button label="Reservation Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
