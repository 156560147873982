import * as React from "react";
import { Link } from "react-router-dom";

import { Table } from "../../../../../../components/Functional";
import { FETCH_OPTIMISED_ITEMS_V2 as fetchItemsV2 } from "../../../../../../graphql/query/Item";

import SectionHeader from "../../components/SectionHeader";

const ItemTotal = props => {
  const { itemTotal = 0.0 } = props || {};
  return (
    <div className="border-b pb-4 pl-[1170px] pt-4 font-bold">
      Total Spend:{" "}
      {Number(itemTotal).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const ContactSales = props => {
  const { contactId } = props || {};
  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "itemsV2",
    headers: [
      {
        label: "Image",
        name: "instance?.edition?.artwork?.imagesSummary?.imgT",
        type: "image",
      },
      {
        label: "Invoice NO.",
        type: "custom",
        component: props => {
          const { invoice } = props?.values || {};
          return (
            <Link className="underline" to={`/invoice/${invoice?.id}/details`}>
              {invoice?.reference}
              {invoice.number}
            </Link>
          );
        },
      },
      {
        label: "Transaction Type",
        type: "custom",
        component: props => {
          const { typeId } = props?.values || {};
          return <div>{typeId === 6 ? "Credit Note" : "Sale"}</div>;
        },
      },
      {
        label: "Artist",
        type: "custom",
        component: props => {
          return (
            <div
              className={`overflow-hidden text-ellipsis text-base font-medium leading-5 text-black`}
              style={{ maxWidth: 250 }}
            >
              {(
                props?.values?.instance?.edition?.artwork?.artists?.map(
                  artist => artist?.name,
                ) || []
              )?.join(", ")}
            </div>
          );
        },
      },
      { label: "Title", name: "instance.edition.artwork.title", type: "label" },
      { label: "Date", name: "invoice.date", type: "date" },
      { label: "Currency", name: "invoice.currency.code", type: "label" },
      { label: "Price", name: "price", type: "currency" },
    ],
    inputs: [
      { label: "Date From", name: "from", type: "date", view: "partial" },
      { label: "Date To", name: "to", type: "date", view: "partial" },
    ],
    footerComponent: ItemTotal,
    FETCH_QUERY: fetchItemsV2({
      instance: {
        edition: { artwork: { artists: {}, imagesSummary: { imgT: {} } } },
      },
      invoice: { currency: {}, type: {} },
    }),
    variables: {
      _invoice: {
        contactId: {
          operator: "eq",
          value: contactId,
        },
      },
      typeId: {
        operator: "in",
        value: [6, 8],
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Sales" />
      <div className="w-full mb-6">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ContactSales;
