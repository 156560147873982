import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import {
  Breadcrumb,
  Button,
  getInput,
  MenuButton,
  Modal,
} from "../../../components/ui";
import {
  CREATE_INSTANCE_JOB as createInstanceJobMutation,
  DELETE_INSTANCE,
  MAKE_AVAILABLE_INSTANCE as makeAvailableInstanceMutation,
  UNCONSIGN_INSTANCE as unconsignInstanceMutation,
  UNDO_STATUS_INSTANCE as undoStatusInstanceMutation,
  UPDATE_INSTANCE as updateInstanceMutation,
} from "../../../graphql/mutation/Instance";
import { FETCH_RESERVATION_INSTANCES_V2 as fetchReservationInstancesV2 } from "../../../graphql/query/ReservationInstance";
import { FETCH_ENTITIES } from "../../../graphql/query/Entity";
import { FETCH_INSTANCE as fetchInstance } from "../../../graphql/query/Instance";
import { FETCH_INSTANCE_DESCRIPTIONS as fetchInstanceDescriptions } from "../../../graphql/query/Instance";
import { FETCH_STATUS } from "../../../graphql/query/Status";

import InstanceSearch from "./InstanceSearch";
import routes from "../pages/Wizard/routes";
import exportModule from "./Actions/ExportModule";
import ChangeEditionNumber from "./ChangeEditionNumber";
import { instanceFilterStore } from "./useInstanceFilters";
import { ExportInstancesBody } from "./Actions/ExportInstances";
import AdhocReportBody from "./Actions/AdhocReportBody";
import AddToOfferSelectList from "./Actions/AddToOfferSelectList";
import StorageEstimate from "./Actions/StorageEstimate";
import { useState } from "react";
import useUser from "../../../hooks/useUser";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const search = instanceFilterStore.get();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const instanceFragmentProps = {
    edition: { artwork: { artists: {}, imagesSummary: { imgT: {} } } },
    status: {},
  };

  const pathnames = location?.pathname.split("/");
  const instanceId = pathnames?.length > 2 ? pathnames[2] : null;

  const instanceObj = {
    condition: { status: {} },
    description: {},
    edition: { artwork: { artists: {}, images: { imgT: {} } } },
    volume: { typeId: 2 },
    weight: {},
  };
  const { data: { instance } = {} } = useQuery(fetchInstance(instanceObj), {
    skip: !instanceId,
    variables: { id: +instanceId },
  });
  const { data: { status: instanceStatus = [] } = {} } = useQuery(FETCH_STATUS);
  const [
    deleteInstance,
    {
      data: { deleteInstance: { error: deleteInstanceError } = {} } = {},
      loading: deleteInstanceLoading,
      reset,
    },
  ] = useMutation(DELETE_INSTANCE);
  const [
    updateInstance,
    {
      data: { updateInstance: { error: updateInstanceError } = {} } = {},
      loading: updateInstanceLoading,
      reset: resetUpdateInstance,
    },
  ] = useMutation(updateInstanceMutation(instanceFragmentProps));
  const [
    unconsignInstance,
    {
      data: { unconsignInstance: { error: unconsignInstanceError } = {} } = {},
      loading: unconsignInstanceLoading,
      reset: resetUnconsignInstance,
    },
  ] = useMutation(unconsignInstanceMutation(instanceFragmentProps));
  const [
    undoStatusInstance,
    {
      data: {
        undoStatusInstance: { error: undoStatusInstanceError } = {},
      } = {},
      loading: undoStatusInstanceLoading,
      reset: resetUndoStatusInstance,
    },
  ] = useMutation(undoStatusInstanceMutation(instanceFragmentProps));
  const [
    makeAvailableInstance,
    {
      data: {
        makeAvailableInstance: { error: makeAvailableInstanceError } = {},
      } = {},
      loading: makeAvailableInstanceLoading,
      reset: resetMakeAvailableInstance,
    },
  ] = useMutation(makeAvailableInstanceMutation(instanceFragmentProps), {
    refetchQueries: [
      fetchReservationInstancesV2({
        reservation: { reservationUser: {} },
      }),
    ],
  });
  const [
    createInstanceJob,
    {
      data: { createInstanceJob: { error: createInstanceJobError } = {} } = {},
      loading: createInstanceJobLoading,
      reset: resetCreateInstanceJob,
    },
  ] = useMutation(createInstanceJobMutation());

  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  const statusMap = {
    all: "All Instances",
    searched: "Searched Instances",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Instances";

  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/instance?status=${status}`) },
    ...(instance
      ? [
          {
            label: instance.name,
            onClick: () => navigate(`${instance.id}/details?status=${status}`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${instance.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const CopyDescriptionModalBody = props => {
    const { closeModal, instance, ids } = props || {};

    const [fetchInstanceDescriptionsLazily] = useLazyQuery(
      fetchInstanceDescriptions(),
      {
        variables: {
          input: {
            id: { operator: "in", value: ids },
          },
        },
      },
    );

    const handleSingularInstance = () => {
      if (instance?.description) {
        const descriptions = instance?.description;
        navigator.clipboard.writeText(descriptions);
        closeModal();
      }
    };

    const handleMultipleInstances = async () => {
      const instances = await fetchInstanceDescriptionsLazily();
      const {
        data: {
          instancesV2: { edges = [] },
        },
      } = instances;
      const descriptions = edges
        .map(instance => instance.description)
        .join("\n");
      navigator.clipboard.writeText(descriptions);
      closeModal();
    };

    return (
      <div className="mt-4 flex">
        <Button
          className="mr-3"
          label={"Cancel"}
          action={"default"}
          onClick={() => {
            closeModal?.();
          }}
        />
        <Button
          disabled={!instance?.description && !ids?.length}
          label={"Copy description"}
          onClick={
            instance?.id ? handleSingularInstance : handleMultipleInstances
          }
        />
      </div>
    );
  };

  const deleteModalProps = {
    title: deleteInstanceError ? "Error" : "Delete Instance?",
    scale: "sm",
    description: deleteInstanceError
      ? deleteInstanceError
      : `Are you sure you wish to delete this instance? This action cannot be undone`,
    closeOnBackdrop: true,
    onClose: () => reset(),
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteInstance({
          variables: {
            input: { id: instance?.id },
          },
          update: (cache, { data }) => {
            const { deleteInstance: { success } = {} } = data || {};
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "Instance",
                  id: instance?.id,
                }),
              });
              navigate(`/instance?status=${status}`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={deleteInstanceError ? "Close" : "Cancel"}
            action={deleteInstanceError ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          {!deleteInstanceError && (
            <Button
              label={deleteInstanceLoading ? "Deleting" : "Delete"}
              disabled={deleteInstanceLoading}
              onClick={handleDelete}
            />
          )}
        </div>
      );
    },
  };

  const createJobModalProps = {
    title: createInstanceJobError ? "Error" : "Create Job",
    scale: "sm",
    description: createInstanceJobError
      ? createInstanceJobError
      : "Are you sure you wish to create job for this instance?",
    closeOnBackdrop: true,
    instance,
    body: ({ closeModal }) => {
      const handleConfirm = () => {
        createInstanceJob({
          variables: {
            id: instance?.id,
          },
          update: (cache, { data }) => {
            const { createInstanceJob: { job, success } = {} } = data || {};
            if (success) {
              closeModal();
              navigate(`/jobs/${job.id}/details`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={createInstanceJobError ? "Close" : "Cancel"}
            action={createInstanceJobError ? "primary" : "default"}
            onClick={() => {
              resetCreateInstanceJob();
              closeModal?.();
            }}
          />
          {!createInstanceJobError && (
            <Button
              label={createInstanceJobLoading ? "Creating" : "create"}
              disabled={createInstanceJobLoading}
              onClick={handleConfirm}
            />
          )}
        </div>
      );
    },
  };

  const setStatusModalProps = ({ status }) => ({
    title: updateInstanceError
      ? "Error"
      : `Set Instance Status to ${status?.name?.toUpperCase()}?`,
    scale: "sm",
    description: updateInstanceError
      ? updateInstanceError
      : `Are you sure you wish to change this instance status to "${status?.name}"?`,
    closeOnBackdrop: true,
    onClose: () => resetUpdateInstance(),
    body: ({ closeModal }) => {
      const handleUpdate = () => {
        updateInstance({
          variables: {
            input: { id: instance?.id, statusId: status?.id },
          },
          update: (cache, { data }) => {
            const { updateInstance: { success } = {} } = data || {};
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={updateInstanceError ? "Close" : "Cancel"}
            action={updateInstanceError ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          {!updateInstanceError && (
            <Button
              label={updateInstanceLoading ? "Updating" : "Update"}
              disabled={updateInstanceLoading}
              onClick={handleUpdate}
            />
          )}
        </div>
      );
    },
  });

  const setMakeAvailableModalProps = ids => ({
    title: makeAvailableInstanceError
      ? `Error: ${makeAvailableInstanceError}`
      : `Make Available?`,
    scale: "sm",
    closeOnBackdrop: true,
    onClose: () => resetMakeAvailableInstance(),
    body: ({ closeModal }) => {
      const handleUpdate = () => {
        const input = {
          description: formik?.values?.description,
          entityId: formik?.values?.entityId,
          ...(ids && ids.length > 0 && { ids: ids }),
          ...(ids?.length < 1 && { id: instance?.id }),
        };
        makeAvailableInstance({
          variables: {
            input,
          },
          update: (cache, { data }) => {
            const { makeAvailableInstance: { success, reservationId } = {} } =
              data || {};
            if (success) {
              closeModal();
              formik?.resetForm();
              if (reservationId) {
                navigate(`/reservations/${reservationId}/details`);
              }
            }
          },
        });
      };

      // eslint-disable-next-line
      const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
          entityId: Yup.string().required("Required"),
          description: Yup.string().required("Required"),
        }),
        onSubmit: handleUpdate,
      });

      // eslint-disable-next-line
      const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);
      const inputs = [
        {
          label: "Entity",
          name: "entityId",
          type: "multi-select",
          options:
            entities?.edges?.map(item => {
              return { label: item.name, value: item.id };
            }) || [],
        },
        { label: "Description", name: "description", type: "textarea" },
      ];

      return (
        <div className="mt-4 flex flex-col">
          {!makeAvailableInstanceError && (
            <div className="mt-4 flex gap-4">
              {inputs?.map((item, index) => {
                return (
                  <div key={index} className="flex w-full flex-col">
                    {getInput({ ...item, formik })}
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-8 flex">
            <Button
              className="mr-3"
              label={makeAvailableInstanceError ? "Close" : "Cancel"}
              action={makeAvailableInstanceError ? "primary" : "default"}
              onClick={() => {
                closeModal?.();
                formik?.resetForm();
              }}
            />
            {!makeAvailableInstanceError && (
              <Button
                label={makeAvailableInstanceLoading ? "Updating" : "Update"}
                disabled={makeAvailableInstanceLoading}
                onClick={formik?.submitForm}
              />
            )}
          </div>
        </div>
      );
    },
  });

  const setUnconsignModalProps = () => ({
    title: unconsignInstanceError ? "Error" : `Unconsign Instance?`,
    scale: "sm",
    description: unconsignInstanceError
      ? unconsignInstanceError
      : `Are you sure you wish to unconsign this instance"?`,
    closeOnBackdrop: true,
    onClose: () => resetUnconsignInstance(),
    body: ({ closeModal }) => {
      const handleUnconsign = () => {
        unconsignInstance({
          variables: {
            input: { id: instance?.id },
          },
          update: (cache, { data }) => {
            const { unconsignInstance: { success } = {} } = data || {};
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={unconsignInstanceError ? "Close" : "Cancel"}
            action={unconsignInstanceError ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          {!unconsignInstanceError && (
            <Button
              label={unconsignInstanceLoading ? "Unconsigning" : "Unconsign"}
              disabled={unconsignInstanceLoading}
              onClick={handleUnconsign}
            />
          )}
        </div>
      );
    },
  });

  const setUndoStatusInstanceModalProps = () => ({
    title: undoStatusInstanceError ? "Error" : `Undo Status Change?`,
    scale: "sm",
    description: undoStatusInstanceError
      ? undoStatusInstanceError
      : `Are you sure you wish to undo status change for this instance"?`,
    closeOnBackdrop: true,
    onClose: () => resetUndoStatusInstance(),
    body: ({ closeModal }) => {
      const handleUndo = () => {
        undoStatusInstance({
          variables: {
            input: { id: instance?.id },
          },
          update: (cache, { data }) => {
            const { undoStatusInstance: { success } = {} } = data || {};
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={undoStatusInstanceError ? "Close" : "Cancel"}
            action={undoStatusInstanceError ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          {!undoStatusInstanceError && (
            <Button
              label={undoStatusInstanceLoading ? "Undoing" : "Undo Status"}
              disabled={undoStatusInstanceLoading}
              onClick={handleUndo}
            />
          )}
        </div>
      );
    },
  });

  const storageEstimateModalProps = {
    closeOnBackdrop: true,
    hideCloseButton: true,
    instance,
    scale: "md",
    body: StorageEstimate,
  };

  const changeEditionNumberModalProps = () => ({
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    instance,
    body: ChangeEditionNumber,
  });

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const [isExportReportOpen, setIsExportReportOpen] = useState(false);
  const { hasPermission } = useUser();
  const disableMakeAvailable = !hasPermission("CREATE_RESERVATION");

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        disabled,
        label: "Export Instances",
        modalProps: {
          body: ExportInstancesBody,
          ...defaultActionProps,
        },
      },
      {
        disabled,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !instance?.id && disabled,
        label: "Export Instance Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: instanceObj,
          type: "Instance",
          ids: instance?.id ? [instance?.id] : ids,
          isExportReportOpen,
          onOpen: () => {
            setIsExportReportOpen(true);
          },
          onClose: () => {
            setIsExportReportOpen(false);
          },
        },
      },
      {
        label: "Add to Offers Select List",
        disabled: !instance?.id && !ids.length,
        modalProps: {
          body: AddToOfferSelectList,
          closeOnBackdrop: false,
          hideCloseButton: true,
          ids: instance?.id ? [instance?.id] : ids,
          listId,
          onClose: () => {
            formik?.resetForm();
          },
          scale: "md",
          search,
          select,
        },
      },
      {
        label: "Make Available",
        modalProps: setMakeAvailableModalProps(ids),
        disabled:
          disableMakeAvailable ||
          (!ids.length && !instance?.id) ||
          (instance && instance?.statusId !== 14),
      },
      {
        label: "Copy Description",
        disabled: !ids.length && !instance?.id,
        modalProps: {
          body: CopyDescriptionModalBody,
          title: "Copy Description?",
          scale: "sm",
          description: "Do you wish to copy the description from the instance?",
          closeOnBackdrop: true,
          ids: ids,
          instance,
        },
      },
      ...(instance?.id
        ? [
            {
              label: "Create Job",
              modalProps: createJobModalProps,
            },
            {
              label: "Delete",
              modalProps: deleteModalProps,
            },
            {
              label: "Return Instance",
              modalProps: setStatusModalProps({
                status: instanceStatus?.find(item => item?.name === "Returned"),
              }),
            },
            {
              label: "Set Status Destroyed",
              modalProps: setStatusModalProps({
                status: instanceStatus?.find(
                  item => item?.name === "Destroyed",
                ),
              }),
            },
            {
              label: "Set Status Inactive",
              modalProps: setStatusModalProps({
                status: instanceStatus?.find(item => item?.name === "Inactive"),
              }),
            },
            {
              label: "Set Status Null",
              modalProps: setStatusModalProps({
                status: instanceStatus?.find(item => item?.name === "Null"),
              }),
            },
            {
              label: "Set Status To Be Returned",
              modalProps: setStatusModalProps({
                status: instanceStatus?.find(
                  item => item?.name === "To be Returned",
                ),
              }),
            },
            {
              label: "Storage Estimate",
              modalProps: storageEstimateModalProps,
            },
            {
              label: "Unconsign",
              modalProps: setUnconsignModalProps(),
            },
            {
              label: "Undo Status Change",
              modalProps: setUndoStatusInstanceModalProps(),
            },
            {
              label: "Change Edition Number",
              modalProps: changeEditionNumberModalProps(),
            },
            {
              label: "Go To Artwork",
              onClick: () =>
                navigate(
                  `/artworks/${instance?.edition?.artwork?.id}/details?status=all`,
                ),
            },
          ]
        : []),
    ],
  };

  const searchModalProps = {
    body: InstanceSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
    isSearchOpen,
    onClose: () => setIsSearchOpen(false),
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button
            label="Instance Search"
            action="black"
            onClick={() => setIsSearchOpen(true)}
          />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
