import { useNavigate } from "react-router-dom";
import {
  reservationFilterStore,
  useReservationFilters,
} from "../useReservationFilters";
import { useLazyQuery } from "@apollo/client";
import { FETCH_RESERVATIONS_V2 as fetchReservationsV2Query } from "../../../../graphql/query/Reservation";
import { Button, Spinner } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";
import { FETCH_RESERVATION_TYPES } from "../../../../graphql/query/ReservationType";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import Tabs from "./components/Tabs";
import Sort from "./components/Sort";
import Query from "./components/Query";
import Instance from "./components/Instance";
import { useEffect } from "react";

const ReservationSearch = props => {
  const { closeModal, isSearchOpen = false } = props || {};
  const navigate = useNavigate();
  const { formik } = useReservationFilters();
  const input = reservationFilterStore.get();
  const [fetchReservationsV2, { loading, data: { reservationsV2 } = {} }] =
    useLazyQuery(
      fetchReservationsV2Query({
        createdBy: {},
        reservationInstance: {},
        reservationUser: {},
        type: {},
      }),
    );

  const [
    fetchReservationTypes,
    { loading: reservationTypesLoading, data: { reservationTypes = [] } = {} },
  ] = useLazyQuery(FETCH_RESERVATION_TYPES);

  const [
    fetchEntities,
    { loading: entitiesLoading, data: { entities = {} } = {} },
  ] = useLazyQuery(FETCH_ENTITIES);

  useEffect(() => {
    if (isSearchOpen) {
      fetchReservationTypes();
      fetchEntities();
    }
  }, [isSearchOpen]);

  const inputs = {
    className: "grid grid-cols-1 gap-4 mt-5",
    inputs: [
      {
        className: "grid gap-4 grid-cols-3",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            options: reservationTypes.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "ID",
            name: "id",
            type: "text",
          },
        ],
      },
      {
        className: "grid grid-cols-3 gap-4",
        inputs: [
          {
            label: "Reserved For *",
            name: "reservationUserId",
            type: "multi-select",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "Date From",
            name: "dateFrom",
            type: "date",
          },
          {
            label: "Date To",
            name: "dateTo",
            type: "date",
          },
        ],
      },
      {
        className: "grid grid-cols-3 gap-4",
        inputs: [
          {
            label: "Created By",
            name: "createdById",
            type: "multi-select",
            options: entities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "End of Reservation from",
            name: "dateDueFrom",
            type: "date",
          },
          {
            label: "End of reservation to",
            name: "dateDueTo",
            type: "date",
          },
        ],
      },
      {
        className: "",
        inputs: [
          {
            label: "Description",
            name: "description",
            type: "text",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: () =>
      fetchReservationsV2({ variables: { input } }).then(() => {
        navigate("/reservations?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    reservationsV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Instance",
        value: "instance",
        component: <Instance {...tabComponentProps} />,
      },

      {
        label: "Sort",
        value: "sort",
        component: <Sort {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Reservation Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      {reservationTypesLoading || entitiesLoading ? (
        <div className="flex h-[30dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <GridInputs {...inputProps} />
          <Tabs {...tabsProps} />
        </div>
      )}
    </div>
  );
};

export default ReservationSearch;
