import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FETCH_CURRENCIES } from "../../../../graphql/query/Currency";
import { FETCH_COMPANIES as fetchCompanies } from "../../../../graphql/query/Company";
import { CREATE_COMPANY as createCompanyMutation } from "../../../../graphql/mutation/Company";
import { Button, getInput } from "../../../../components/ui";

const NewCompany = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const [createCompany, { loading }] = useMutation(createCompanyMutation(), {
    refetchQueries: [fetchCompanies()],
  });

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a company name"),
      contactId: Yup.number()
        .required("Please select a contact")
        .typeError("Please select a contact"),
      currencyId: Yup.number().required("Please select a currency"),
    }),
    onSubmit: async values => {
      createCompany({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createCompany: { success, company } = {} },
        } = resp || {};
        if (success) {
          navigate(`/companies/${company?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const inputs = [
    { label: "Name *", name: "name", type: "text" },
    {
      label: "Contact *",
      name: "contactId",
      type: "contactSearch",
    },
    {
      label: "Base Currency *",
      name: "currencyId",
      type: "multi-select",
      isMulti: false,
      options: currencies?.map(currency => ({
        label: `${currency.description} (${currency.code})`,
        value: currency.id,
      })),
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new company</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs
          ?.filter(item => !!item)
          .map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
      </div>
    </div>
  );
};

export default NewCompany;
